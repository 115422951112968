import { Styling } from '~/utils'
import { PRIMARY_COLOR } from '../globals'

const S = Styling({
	Checkmark: { width: 15, height: 15 },
	Outline:  {
		// flex: 1,
		// flexDirection: 'column',
		flexGrow: 1,
		minWidth: 200,
		// maxWidth: 300,
		maxWidth: '100%', // 300
	},
	TreeNode: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 3, marginLeft: 5,
	},
	TreeNodeHeading: { marginRight: 0, overflow: 'hidden', flexDirection: 'column' },
	BranchIndicator: { width: '1em', alignSelf: 'center', fontSize: 16, fontWeight: 'bold' },
	SelectedNodeIndent: {
		width: 2,
		height: '100%',
		marginLeft: 10
	},

	OutlineList: {
		display: 'block',
		backgroundColor: PRIMARY_COLOR,
		flexDirection: 'column',
	},
	OutlineItem: { flexDirection: 'row' },
	OutlineItemHeading: { marginRight: '0.5em' },
	Score: {
		// marginLeft: '0.5em',
		fontWeight: 'bold',
		//position: 'absolute',
		//right: '0.5em',
	},
	Tabs: {
		flexDirection: 'row',
		marginRight: '-5px',
	},
	Tab: {
		flex: 1,
		// Needed for ipad:
		display: 'block',
		marginRight: 5,
		borderTopLeftRadius: 10, borderTopRightRadius: 10,
		overflow: 'hidden', flexDirection: 'column',
	},
	TabLink: {
		// display: 'block',
		backgroundColor: PRIMARY_COLOR,
		padding: 5,
		// specify a height specifically to make Safari render it
		// height: '100%',
	},
	TabIcon: {
		height: 50,
	},
	TabConnector: {
		height: 3,
	},
	TabGap: {
		height: 3,
		display: 'hidden'
	}
})

export { S }
