import { useCallback } from 'react'
import { prop } from 'ramda'
import { hh } from '~/utils'
import { Text, TextInput, TouchableOpacity, View } from '~/utils/Tags/react-native'
import { IItemProps, HComponent } from '~/interfaces'
import { getAnswerOption, AnswerOptionTypes } from 'ttss/Taxonomy/Items/MultipleChoiceItem/AnswerOption'
import { S } from './styles'
import { CustomOptionRenderer, CustomOptionsControls, OptionRenderer, OptionRendererProps } from './utils'
import { MultipleChoiceItem } from 'ttss/Taxonomy/MultipleChoiceItem'
import { Component, useContext } from 'react'
import { AssessmentContext } from '~/Assessments/AssessmentContext'
import { PredictionAutoFillProps } from './AutoFill'


type CustomOptionQuestionProps = IItemProps<typeof MultipleChoiceItem> & OptionRendererProps
export const CustomOptions = (component: CustomOptionRenderer) => {
//export const CustomOptions = (optionComponent: CustomOptionRenderer): HComponent<CustomOptionQuestionProps> => {
	// const optionComponent: CustomOptionRenderer = hh(component, component?.displayName + '-Prediction')
  // TODO: ideally this would be MultiSelect | SingleSelect, but that wasn't recognizing CustomOption props
	return hh((props: CustomOptionQuestionProps) => {
		// public predictedOption
		// public predictionError
		const { save } = useContext(AssessmentContext)
		const { item } = props

		// const renderOptionControl = (option, key) => {
		// 	const { name, description } = getOption(option)
		// 	const item = props.item
		// 	const label = name + (description ? ' - ' + description : '')
		// 	const isSelected = item.isSelected(option)
		// 	return renderer(label, name, isSelected, select(name, key), key, props.predictedOption)
		// }

		// const submit = (newValue, options?) => submit(item, options)

		const select = (name: string, _key?: string | number) => () => {
			// item.toggle(name)
			item.selectOption(name)
			save(item)
		}

		const addOther = () => {
			if (!item.hasOther || !item.config.isSingleSelect) {
			item.addOther()
				return select('')
			}
		}

		const deleteOther = (key: number | string) => {
			console.log('deleting', key)
			item.deleteOther(key)
			save(item)
		}

		const editOther = (idxOrKey: number | string) =>
			(newName: string) => {
				console.log('editing other:', idxOrKey)
				item.editOther(idxOrKey, newName)
				save(item)
			}

		const getOtherOptions = useCallback(() => {
			return props.item.otherOptions
		}, [props.item.response?.value])

		const canAddOther = (tem) => !(tem.hasOther && tem.config.isSingleSelect)

		const renderOtherOptionControl = useCallback((uiControl: OptionRenderer, option: AnswerOptionTypes, key: number | string) => {
			// const { item } = props
			const { name } = getAnswerOption(option)
			// const label = name + (description ? ' - ' + description : '')
			// const isSelected = item.isSelected(option)

			// TODO: other options need IDs to identify themm
			// using name for key causes the text box to lose focus while typing
			// using just `key` causes the view  to not update correctly when the index changes
			return View(S.OtherOptionContainer, { key: 'other_' + key  }, [
				uiControl,
				// component(label, name, isSelected, select(option, key), key, predictedOption),
				// renderer(label, name, isSelected, select(option, key), key, predictedOption),
				// renderer(S.Checkbox, { value: true }),
				TextInput(S.OtherOptionInput, {
					defaultValue: name ?? '',
					onChangeText: editOther(key),
				}),
				TouchableOpacity({ onPress: () => deleteOther(key) }, [ Text(S.DeleteOption, ['X']) ]),
			])
		}, [editOther])

		const renderAddOptionControl = () => {
			if (item.canAddOther) {
				return TouchableOpacity(S.AddOption, { onPress: addOther }, [
				Text(S.AddOptionText, ['Add a new option']),
			])
			}
		}

		return component({
			...props,
			addOther,
			deleteOther,
			editOther,
			getOtherOptions,
			renderAddOptionControl,
			renderOtherOptionControl,
		})
	}, '${component?.displayName}WCustomOption')
}
