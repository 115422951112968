import * as CSS from 'csstype'
import { hh } from '~/utils'
import { Button, ScrollView, Text, View } from '~/utils/Tags/react-native'
import { S } from './style'

// TouchableWithoutFeedback(Style.Backdrop, { onPress: () => onCloseClick() }),
interface IPopupProps {
	title: string
	children: any[]
	buttons?: any[]
	backdrop?: boolean
	position?: CSS.Properties['position']
	onCloseClick(...args: any[]): void
	// TODO: lifecycle hooks
	// onBeforeClose
	// onAfterClose
	// onBeforeOpen
	// onAfterOpen
}
export const Popup = hh(
	({ title, onCloseClick, children, buttons, backdrop = true, position = 'fixed' }: IPopupProps) =>
		View(S.Popup, { style: { position } }, [
			backdrop && View(S.Backdrop),
			View(S.PopupContainer, [
				View(S.PopupHeader, [Text(S.PopupTitle, [title])]),
				ScrollView(S.PopupContents, children),
				buttons
					? View(S.Buttons, buttons)
					: View(S.PopupFooter, [
						Button({ title: 'Close', color: '#CF6F59', onPress: onCloseClick }),
					]),
			]),
		]),
	'Modal',
)



// interface ICommentPopupProps {
// 	item: TaxonomyInstance
// 	submit: AssessmentContext['save']
// 	selectedPathItems: TreePathItems
// 	showChildren: boolean
// 	onCloseClick(): void
// }

// export const CommentPopup = hh(
// 	(props: ICommentPopupProps) => {
// 		const { selected } = useNavigationContext()
// 		const { onCloseClick, submit } = props
// 		const sections = selected.pageItems.map(taxonomyItem => CommentField({ item: taxonomyItem }))
// 		return Popup({ title: 'Add Comments', onCloseClick }, [sections])
// 	},
// 	'CommentPopup',
// )

// interface ICameraPopup {
// 	item: TaxonomyInstance
// 	showChildren?: boolean
// 	onCloseClick(): void
// }

// // Unused
// export const CameraPopup = hh(
// 	(props: ICameraPopup) => {
// 		const { selected } = useNavigationContext()
// 		const { onCloseClick } = props

// 		return Popup(
// 			{ title: 'Add Photos & Videos', onCloseClick },
// 			selected.pageItems.map(item => PhotoField({ item })))
// 	},
// 	'CameraPopup',
// )
