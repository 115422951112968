import { propEq } from 'ramda'
import { QCode } from 'ttss/Taxonomy/utils'
import { ProxyItem } from 'ttss/Taxonomy/GroupItem'

import { ItemType } from '~/Item'
import { HComponent, IItemProps } from '~/interfaces'

export const ProxyField: HComponent<IItemProps<typeof ProxyItem>> =
	(props: IItemProps<typeof ProxyItem>) => {
		const proxiedItem = props.item
		const proxy = proxiedItem
			.root
			.descendants.filter(propEq('Name', proxiedItem.config.ref))[0]
		return ItemType.render(proxy) // RenderItem({ item })
	}

export const Proxy = ItemType.create({ name: QCode.Proxy, field: ProxyField })
