import { hh } from "~/utils"
import { Image, Text } from '~/utils/Tags/react-native'
import { fileSrc } from "~/utils/assets"
import { Spacer } from "../Helpers/Spacer"
import { getFormValues, ICollectionRendererOptions } from "./utils"
import { S } from "./styles"
import { IPhoto } from '~/interfaces'

import { observer } from 'mobx-react'
export const Animal = hh(observer(
	(props: ICollectionRendererOptions) => {
		const response = getFormValues(props.item)
		const image = response['Image(optional)'] as IPhoto
		const { Name, Species } = response

		return [
			!!image && Image(S.ItemIcon, fileSrc(image.src)),
			Text([ Name ]),
			Spacer,
      Species && Text([ Species ]),
			// Species && Text([ Species[0].name ]),
		]
	}),
	'Animal',
)
