export interface IDate {
	day: number
	month: number
	year: number
}

export const CURRENT_DATE = new Date()
export const CURRENT_YEAR = CURRENT_DATE.getFullYear()
export const MONTH_NAMES = [
	'January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December',
]

export function YearMonthDayFromDate(date: Date): IDate {
	return {
		year: date.getFullYear(),
		month: date.getMonth(),
		day: date.getDate(),
	}
}

export type DateRange = [ to: Date|IDate, from: Date|IDate ]
