import { hh } from "~/utils"
import { View, Text } from "~/utils/Tags/react-native"
import { Spacer } from "../Helpers/Spacer"
import { S } from "./styles"
import { getFormValues, ICollectionRendererOptions } from "./utils"

import { observer } from 'mobx-react'
export const Thumbnail = hh(observer(
	(props: ICollectionRendererOptions) => {
		const response = getFormValues(props.item)
		// const image = response['Image(optional)'] as IPhoto
		const name = [response.Caption].join(' ')

		return [
			View(S.ListItem, [
				Text(S.ListItemText, [name.length ? name : '(empty)']),
				Spacer,
				Text(S.ListItemText, [response.updated ?? '7/2/2018']),
				Spacer,
				Text(S.ListItemText, [response.Size ?? '1mb']),
				// Image(S.ThumbnailImage, fileSrc(get(image, ['Add photo', 'src']))),
			]),
		]
	}),
	'Thumbnail',
)
