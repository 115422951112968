import { Styling } from '~/utils'

export const Style = Styling({
	Button: {
		width: 100,
		height: 150,
		marginRight: 10,
		borderStyle: 'solid',
		borderColor: 'black',
		borderWidth: 1,
		borderRadius: 10,
		flexDirection: 'column',
		padding: 8,
	},
	TextEntry: {
		// flex: 1,
		justifyContent: 'space-between',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',

		// marginTop: 5,
		// marginBottom: 5,
	},
	TextInput: {
		flex: 1,
		maxWidth: 250,
		backgroundColor: '#E8E8E8',
		padding: 2,
		borderColor: '#888',
		borderWidth: '1px',
		borderRadius: '5px'
	},
	DateItem: {
		// flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 5,
		marginBottom: 5,
		alignItems: 'center',
	},
	AttachmentButton: { width: '100%', height: '100%', resizeMode: 'contain' },
})

