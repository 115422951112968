import { Image, Text, TouchableOpacity, View } from '~/utils/Tags/react-native'
import { Style } from '../styles'
import { hh } from '~/utils'
import { svg } from '~/utils/assets'

export interface IAttachmentButtonProps {
	text: string
	backgroundColor?: string
	required?: boolean
	isButton?: boolean
	value?: any
	onPress(): void
}

const Required = (required: boolean) => required && Text(['*required'])

export const AttachmentButton = hh(
	({
		text, backgroundColor = '#E8E8E8',
		required = false,
		isButton = false,
		onPress,
		value,
	}: IAttachmentButtonProps) =>
		TouchableOpacity(Style.Button, { style: { backgroundColor }, onPress }, [
			Text([ text ]),
			value && Text([ '✓' ]),
			isButton && View({ style: { flex: 1 } }, [
				Image(Style.AttachmentButton, svg('assessments/paperclip.svg')),
			]),
			Required(required),
		])
)
