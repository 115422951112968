import { useState, useCallback, SetStateAction, useDebugValue, useMemo } from 'react'
import { useHandler } from './useHandler'

export type Toggler = (newState?: SetStateAction<boolean>) => void

/**
 * Toggle state. In contrast to `useToggle` this hook accepts an argument to
 * set a specific state rather than just toggling.
 */
export function useToggler(initialState: boolean) {
	const [state, setState] = useState<boolean>(initialState)
	useDebugValue(state)
	const toggleState: Toggler = newState => {
		if (newState === true || newState === false) {
			setState(newState)
			return newState
		} else {
			// let state: boolean
			setState(oldState => !oldState)
			return state
		}
	}

	return [state, useHandler(toggleState)] as const
}

export function useToggleMap<T extends {}>(initialState: T) {
	const [state, setState] = useState<T>(initialState)
	useDebugValue(state)

	const createToggler = useMemo(
		() =>
			<N extends keyof T>(stateName: N): Toggler =>
				(newState?: SetStateAction<boolean>) => {
					console.log('Toggling', stateName)
					if (newState === true || newState === false) {
						setState((oldState) => ({
							...oldState,
							[stateName]: newState,
						}))
						return newState
					} else {
						// let state: boolean
						setState(oldState => ({
							...oldState,
							[stateName]: !oldState[stateName]
						}))
						return state
					}
				},
		[],
	)
	return [state, createToggler] as const
}


// const toggleState = useCallback(
// 	(newState?: boolean | any) => {
// 		if (newState === true || newState === false) {
// 			setState(newState)
// 			return newState
// 		} else {
// 			let state: boolean
// 			setState(oldState => {
// 				state = !oldState
// 				console.log({ state, oldState })
// 				return state
// 			})
// 			return state
// 		}
// 	}, [state])

		// const toggleIsOpen = useCallback(
		// 	(newState?: boolean | any) => {
		// 		if (newState === true || newState === false) {
		// 			openRef.current = newState
		// 		} else {
		// 			openRef.current = !openRef.current
		// 		}
		// 		return openRef.current
		// 	}, [])
