import { Styling } from '~/utils'

export const S = Styling({
	ProfilePicker: {
		flexDirection: 'row',
	},
	PickerButtons: {
		flexDirection: 'column',
		alignItems: 'center',
		marginLeft: '1em',
	},
	ProfileImageContainer: {
		width: 200,
		height: 200,
		borderStyle: 'solid',
		borderColor: 'black',
		borderWidth: 8,
		borderRadius: 100,
		overflow: 'hidden',
	},
	ProfileImage: { width: '100%', height: '100%' },
})
