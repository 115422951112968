import { hh } from './hh'

import _Svg, {
	Circle as $Circle,
	ClipPath as $ClipPath,
	Defs as $Defs,
	Ellipse as $Ellipse,
	G as $G,
	Image as $Image,
	Line as $Line,
	LinearGradient as $LinearGradient,
	Path as $Path,
	Polygon as $Polygon,
	Polyline as $Polyline,
	RadialGradient as $RadialGradient,
	Rect as $Rect,
	Stop as $Stop,
	Symbol as $Symbol,
	Text as $Text,
	TextPath as $TextPath,
	TSpan as $TSpan,
	Use as $Use,
} from 'svgs'

export const Circle = hh($Circle)
export const ClipPath = hh($ClipPath)
export const Defs = hh($Defs)
export const Ellipse = hh($Ellipse)
export const G = hh($G)
export const SVGImage = hh($Image)
export const Line = hh($Line)
export const LinearGradient = hh($LinearGradient)
export const Path = hh($Path)
export const Polygon = hh($Polygon)
export const Polyline = hh($Polyline)
export const RadialGradient = hh($RadialGradient)
export const Rect = hh($Rect)
export const Stop = hh($Stop)
export const Symbol = hh($Symbol)
export const SVGText = hh($Text)
export const TSpan = hh($TSpan)
export const TextPath = hh($TextPath)
export const Use = hh($Use)

// This wouldn't import with 'import x as y' syntax?
// ... so doing weird work-around
export const Svg = hh(_Svg)
