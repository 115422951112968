import { assetSrc } from '~/utils/assets'
import { hh, Styling } from '~/utils'
import { Image, Text, TouchableOpacity } from '~/utils/Tags/react-native'

export interface ButtonProps {
	text?: string,
	icon?: string,
	style?: any
	
	onPress(...args: any[]): void
}
export const IconButton = hh(
	({ onPress, icon, text, style = {} }: ButtonProps) =>
		TouchableOpacity(S.Button, {
			onPress,
			accessible: true,
			accessibilityLabel: text,
			label: text,
			style,
		}, [
			icon && Image(S.ButtonImage, assetSrc(icon)),
			text && Text([text]),
		]),
	'Button'
)

const S = Styling({
	Button: {
		width: 140,
		borderColor: 'black',
		borderWidth: 3,
		borderStyle: 'solid',
		borderRadius: 10,
		flexDirection: 'column',
		alignItems: 'center',
		padding: 5,
		justifyContent: 'center',
		textAlign: 'center',

		// marginBottom: 10,
		// marginRight: '1em',
		// marginBottom: '1em',
	},
	ButtonImage: {
		width: 40,
		height: 40,
		resizeMode: 'contain',
	}
})
