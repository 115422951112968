import { hh } from '~/utils/Tags/hh'
import { Text } from '~/utils/Tags/react-native'
import { Heading, Section, Btn } from '~/components'

export const ResetHESTIA = hh(
	({ }) => {
		const reset = () => {
			console.log('Resetting state')
			localStorage.clear()
			// resetErrorBoundary()
			// reload
			window.location.href = window.location.href
		}
		return Section([
			Heading(['Reset Hestia']),
			Text(['If you continue to encounter this problem you can try to reset your browser data.']),
			Text(['Any unsaved data will be lost.']),
			Btn({ title: 'Reset HESTIA', style: { alignSelf: 'flex-end' }, onPress: reset }),
		])
	},
	'ResetHESTIA',
)
