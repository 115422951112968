import { svg } from '~/utils/assets'
import { View } from '~/utils/Tags'
import { Styling, useHandler, useToggler, } from '~/utils'
import { ItemControlsProps } from './interfaces'
import { IconButton } from '../components/IconButton'

export const HelpToggle = () => {}
export const DebuggerToggle = () => {}
export const AddCommentButton = () => {}
export const AddImageButton = () => {}

const S = Styling({
	Controls: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '1ex',
		marginTop: '5px',
		float: 'right',
		marginRight: '-15px',
		// position: 'absolute',
		// right: 0,
		// top: 0,
	}
})

export const DefaultItemControls = (props: ItemControlsProps) => {
	const { createToggler } = props
	return View(S.Controls, [
		HelpToggle({ onPress: useHandler(createToggler('help')) }),
		process.env.NODE_ENV === 'development'
			&& EditItemToggle({ onPress: useHandler(createToggler('editor')) }),
		// DebuggerToggle({ onPress: useHandler(createToggler('debug')) }),
		CommentsToggle({ onPress: useHandler(createToggler('comment'))}),
		PhotosToggle({ onPress: useHandler(createToggler('photo')) }),
	])
}

export const CommentIcon = svg('assessments/itemNote.svg')
export const PhotoIcon = svg('assessments/itemPhoto.svg')
export const EditIcon = svg('pen_thin.svg')
const CommentsToggle = ({ onPress }) => IconButton({ icon: CommentIcon, onPress })
const PhotosToggle = ({ onPress }) => IconButton({ icon: PhotoIcon, onPress })
const EditItemToggle = ({ onPress }) => IconButton({ icon: EditIcon, onPress })
