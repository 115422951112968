import { QCode } from 'ttss/Taxonomy/utils'
import { equals, propEq } from 'ramda'

import { Styling, } from '~/utils'
import { View } from '~/utils/Tags'
import { useNavigationContext } from '~/Assessments/useTaxonomyNavigation'
import { ACCENT_COLOR, SECONDARY_COLOR, TERTIARY_COLOR } from '~/Assessments/globals'

import { getQuestionText, ItemDescription, ItemTitle, QuestionPrompt } from '~/Item/Prompt'
import { ItemLayoutProps } from './interfaces'
import { nodeToString, stringifyNode } from 'ttss/utils'
import { DefaultItemControls } from './Controls'
import { ItemType } from './'
import { useToggleMap } from '~/utils/useToggler'
import { CommentField } from '~/components/Popups/CommentPopup'
import { PhotoField } from '~/ItemTypes/Photos'

export const DefaultItemLayout =
	({ item }: ItemLayoutProps) => {
		// const { selected } = useNavigationContext()
		const { text, description } = getQuestionText(item)
		const [ shouldShow, createToggler ] = useToggleMap({
			comment: false,
			photo: false,
			editor: false,
			debug: false,
			help: false,
		})
		// const containsSelection = item === selected || item.descendants.find(equals(selected))
		const isInGroup = item.ancestors.find(propEq('Type', QCode.Group))
		const showControls = !isInGroup

		const itemMetaFields = showControls && View(S.MetaFields, [
			shouldShow['comment'] && CommentField({ item }),
			shouldShow['photo'] && PhotoField({ item }),
		])

		// TODO: inline should have normal weight
		// inline is not being detected correctly so fontWeight needs to be set in prompt
		const inline = item.inline ?? item.style?.inline
		const styles = inline// || item.Type === 'H' || item.Type === 'U' // Text/Date input
			? inlineItemStyle
			: defaultItemStyle

		const renderer = ItemType.registry.lookup(item.Type)
		const layout = View(S.Layout, [
			// Icon?
			// Score?
			showControls && renderer.Controls({ createToggler }),
			View(S.MainLayout, { style: styles }, [
				renderer.Heading({ item }),
				description && ItemDescription({ description }),
				!inline && itemMetaFields,
				renderer.Field({ item }),
				inline && itemMetaFields,
			]),

			// Errors/Warning
			// Related Questions Links/Dependencies
			// Help/Info
		])

		return shouldShow['editor']
			? renderer.Editor({ item }, [ layout ])
			: layout
	}

export const S = Styling({
	Layout: {
		display: 'block',
	},
	MainLayout: {
		flex: 1,
		// justifyContent: 'space-between',
		// flexDirection: 'row',
		marginTop: 5,
		marginBottom: 5,
		flexWrap: 'wrap',
		alignItems: 'center',
	},
	Title: {

	},
	Item: {
		display: 'block',
		alignItems: 'flex-start',
		flexShrink: 1,
	},
	ItemLayout: {
		// display: 'block',
		// alignItems: 'flex-start',
		display: 'flex',
		// flex: 1,
		flexShrink: 1,
		flexGrow: 1,
		padding: '.2em',
		paddingLeft: '.8em',
		paddingRight: '1em',
		// minWidth: '65ex',
		maxWidth: '65ex',
		backgroundColor: ACCENT_COLOR,
		borderRadius: '.4em',
		borderStyle: 'inset',
		marginBottom: '.2em',
		overflow: 'hidden',
	},
	Editor: {

	},
	MetaFields: {
		border: '1px solid lightgray',
		borderRadius: '4px',
		marginTop: '1ex',
	}
})

const inlineItemStyle = {
	flexDirection: 'row',
	alignItems: 'center',
	minWidth: 100,
	fontWeight: 'normal',
}
const defaultItemStyle = {
	flexDirection: 'column',
	alignItems: 'flex-start',
	fontWeight: 'bold',
}
