import { useState } from 'react'
import { hh } from '~/utils/Tags'
import { Text, View } from '~/utils/Tags/react-native'
import { Popup } from '~/components/Popups'
import { useUploadInput } from '~/components/UploadButton'
import { NavButton, NavButtonText } from './NavButton'
import { useContext } from 'react'
import { downloadFile, useToggler } from '~/utils'
import { AssessmentContext } from '../AssessmentContext'
import { exportTaxonomy } from './exportTaxonomy'
import { VersionInfo } from './VersionInfo'
import { getDiagnostics } from '~/ErrorReporting/diagnostics'
import { useFileUpload } from '~/utils/useFileUpload'

export const Menu = hh(
	({ toggleShowMenu }) => {
		const { assessment, newAssessment, load } = useContext(AssessmentContext)
		const onUpload = (newFiles: File) => files[0].text().then(load)
		const [ files, triggerUploadPrompt ] = useFileUpload(onUpload)
		const download = () =>
			downloadFile(assessment.serialize(), `assessment-${Date.now()}.hestia`)
		// downloadJson(omit(['taxonomy'])(getSnapshot(assessment)), `assessment-${Date.now()}.hestia`)

		const exportReport = () =>
			downloadFile(exportTaxonomy(assessment.taxonomy), `assessment-report${Date.now()}.tsv`)

		const [ fakeError, setFakeError	] = useState(false)
		if (fakeError) throw new Error("An example error has occurred." )
		return Popup({ title: 'Menu', onCloseClick: toggleShowMenu }, [
			Text(['Assessments']),
			NavButton({ onPress: newAssessment }, [ NavButtonText('New Assessment') ]),
			NavButton({ onPress: triggerUploadPrompt }, [NavButtonText('Load Assessment File')]),
			NavButton({ onPress: download }, [NavButtonText('Save Assessment File')]),
			Text(['Taxonomy']),
			NavButton({ onPress: exportReport }, [NavButtonText('Load Taxonomy File')]),
			NavButton({ onPress: exportReport }, [NavButtonText('Import Taxonomy From Sheet')]),
			Text(['Reporting']),
			NavButton({ onPress: exportReport }, [NavButtonText('Export Assessment as TSV')]),
			VersionInfo({ diagnostics: getDiagnostics() }),

			NavButton({ onPress: () => setFakeError(true) }, [ NavButtonText('Cause a fake error!') ]),
		])
	},
	'Menu',
)

