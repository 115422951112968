import { useState } from 'react'
import { useHandler } from './useHandler'
import { v4 as uuid } from 'uuid'
import { Photo } from 'ttss/Taxonomy/DataTypes'

export const photoAttachmentFromFile = (file: File) => {
	const photoAttachment = Photo.create({
		id: uuid(),
		fileName: file.name,
		size: file.size,
		type: file.type,
		lastModified: file.lastModified,
	})
	photoAttachment.setFile(file)
	return photoAttachment
}

export type FileUploadCallback = (newFiles: File[], existingFiles: File[], event: Event) => any
export const useFileUpload = (callback?: FileUploadCallback, accept = '', multiple = false) => {
	const [ files, setFiles ] = useState<File[]>([])
	const [ error, setError ] = useState<string>()

	const onChange = useHandler(
		(event: Event) => {
			// const target = event.currentTarget as HTMLInputElement
			const eventFiles = (event.currentTarget?.files ?? event.dataTransfer?.files) as File[]
			if (!eventFiles) {
				console.error('There were no files in the upload event.')
				// setError('No ')
			}
			const newFileArray = Array.from(eventFiles)
			setFiles(files => [...files, ...newFileArray])
			if (callback) callback(newFileArray, files, event)
		})

	const triggerUploadPrompt = () => triggerUpload(onChange, accept, multiple)
	const clear = () => setFiles([])
	return [
		files,
		useHandler(triggerUploadPrompt),
		useHandler(clear),
		error,
	] as const
}
// const id = `${RandomId()}/${file.name}`

const handleDragDropEvent = (e: Event) => {
	e.stopPropagation();
	e.preventDefault();
}

function createFileInputElement({ multiple, accept }) {
	const input = document.createElement('input')
	// set input config
	input.type = 'file'
	input.accept = accept
	input.multiple = multiple
	return input
}

const triggerUpload = (callback, accept = '', multiple = false) => {
	// create virtual input element
	const inputEL = createFileInputElement({ multiple, accept })
	// add event listener
	inputEL.addEventListener('change', callback)
	inputEL.click()
}

// export const RandomId = () => (Math.floor(Math.random() * 10000) + 1) + '' + new Date().getTime()

// const setFiles = useCallback(
// 	(e: any, mode = 'w'): void => {

// 		if (mode === 'w') {
// 			setFilesState(filesArr);
// 		} else if (mode === 'a') {
// 			setFilesState([...files, ...filesArr]);
// 		}
// 	},
// 	[files],
// );

// const removeFile = useHandler(
// 	(file: number | File): void => {
// 		if (typeof file === 'number') setFilesState(files.filter((_file: File, i) => i !== file))
// 		else setFilesState(files.filter((_file: File) => _file === file))
// 	},
// 	[files],
// )
