import { hh } from '~/utils'
import { svg } from '~/utils/assets'
import { Image, TouchableOpacity, View } from '~/utils/Tags/react-native'
import { TaxonomyInstance, TreePath } from '~/interfaces'
import { S } from './styles'
import { useNavigationContext } from '../useTaxonomyNavigation'

type Select = (path: TreePath) => void

const TabConnector = View(S.TabConnector)
const TabGap = View(S.TabGap)
interface ITabProps {
	icon: string | undefined // TODO: check or error?
	isSelected: boolean
	key: string
	select(): void
}

function Tab({ icon, isSelected, select, key }: ITabProps) {
	return View(S.Tab, { key }, [
		TouchableOpacity(S.TabLink, { onPress: select }, [
			icon && Image(S.TabIcon, svg(icon)),
			// visually connects the selected tab to the outline:
			isSelected &&  TabConnector
		]),
	])
}

interface TabProps {
	taxonomy: TaxonomyInstance
	selected: number
}
export const Tabs = hh(
	({ taxonomy, selected }: TabProps) => {
	const { selectByPath } = useNavigationContext()
	return View(
		S.Tabs,
		taxonomy.items.map(
			(tab, index) => Tab({
				key: 'tab' + index,
				icon: tab.icon,
				isSelected: index === selected,
				select: () => selectByPath([index]),
			}),
		))
	}, 'OutlineTabs')

