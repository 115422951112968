import { TaxonomyInstance } from '~/interfaces'

/**
 * Generate a unique item key for react.
 */
export const generateItemKey = (item: TaxonomyInstance) => {
	// TODO: collection items are problematic because the id is the same
	//       for each item in the list, and selfIndex might not correctly reflect
	//       the changing data. Revisit this? Maybe put generateKey on ttss base so that
	//       it can be overridden?
	return '' + item.id + '-' + item.selfIndex + '-' + item.assessment.id
}
