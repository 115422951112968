import { hh } from "~/utils"
import { View, Text } from '~/utils/Tags/react-native'
import { Spacer } from "../Helpers/Spacer"
import { S } from "./styles"
import { ICollectionRendererOptions, getFormValues } from "./utils"

export const Attachment = hh(
	(props: ICollectionRendererOptions) => {
		const response = getFormValues(props.item)
		// const image = response['Image(optional)'] as IPhoto
		const name = [response.Caption].join(' ')
		return [
			// !!image && Image(S.ItemIcon, fileSrc(image.src)),
			View(S.ListItem, [
				Text(S.ListItemText, [
					name.length
						? name
						: '(empty)'
				]),
				Spacer,
				Text(S.ListItemText, [
					response.Size ?? '1mb'
				]),
			]),
		]
	},
	'Attachment',
)
