import { useState } from 'react'
import { hh, pre } from '~/utils/Tags/hh'
import { Text, TextInput, Linking } from '~/utils/Tags/react-native'
import { useIssueReportedState } from '~/storage'
import { diagnosticsAsString } from './diagnostics'
import { Heading, Section, Btn } from '~/components'

export const IssueReporter = hh(
	({ uuid, diagnostics, error }) => {
		const [issueReported, setIssueReported] = useIssueReportedState()
		const [ comment, setComment	] = useState('')
		const errorText = (error?.message ?? error) + '\n' + error?.stack
		const data = `
Generated on ${diagnostics.time}
id: ${uuid}

${diagnosticsAsString(diagnostics)}
`

		const submit = async () => {
			const body = `I encountered an while using HESTIA:

${comment}

===================================================
Debug Information Below - Do not delete ###
===================================================

${data}

Error: ${errorText}
`
			const params = new URLSearchParams({ subject: `HESTIA Feedback: ${uuid}`, body })
			await Linking.openURL('mailto:nlspaeth@uwm.edu?' + params.toString())
			setIssueReported(true)
		}
		if (issueReported) return Section([ Text('You have reported this issue.') ])
		// !issueReported
		return Section([
			Heading([ 'Send Feedback' ]),
			Text([ 'The following information will be submitted: ']),
			pre({ style: { overflow: 'auto', backgroundColor: '#ddd', border: '1px solid darkgray', borderRadius: 5, padding: 5 }}, [ data ]),
			Text([ 'What were you doing when you encountered this error? (optional)' ]),
			TextInput({
				accessible: true,
				accessibilityLabel: 'How the error was encountered',
				defaultValue: '',
				multiline: true,
				textAlignVertical: 'top',
				onChangeText: text => setComment(text),
				style: {
					backgroundColor: '#eee',
					height: 80,
					margin: 12,
					borderWidth: 1,
					padding: 10,
				}
			}),
			Btn({ title: 'Create Feedback', onPress: submit, style: { alignSelf: 'flex-end' } }),
		])
	},
	'IssueReporter',
)
