import { Styling } from '~/utils'

const S = Styling({
	Popup: {
		// Position is applied via style attribute
		top: 0, left: 0,
		width: '100%', height: '100%', zIndex: 1000,
		flex: 0, alignItems: 'center', justifyContent: 'center',
	},
	PopupHeader: {
		alignItems: 'center', height: 40, flexDirection: 'row', backgroundColor: '#eeeeee',
		overflow: 'hidden', borderTopRightRadius: 10, borderTopLeftRadius: 10,
	},
	PopupTitle: { flex: 1, textAlign: 'center', fontSize: 18, alignSelf: 'center', color: '#D07057' },
	PopupFooter: {
		marginBottom: 10, width: 100, borderRadius: '50', alignSelf: 'center', overflow: 'hidden',
	},
	PopupContents: { flex: 1, flexDirection: 'column', margin: 10, padding: 16 },
	PopupContainer: {
		overflow: 'hidden', width: 450, height: 550, backgroundColor: 'white',
		alignSelf: 'center', borderRadius: 10, flexDirection: 'column',
	},
	TextEntry: { flexDirection: 'row', marginTop: 5, marginBottom: 5 },
	TextInput: { flex: 1, maxWidth: 250, backgroundColor: '#E8E8E8', padding: 2 },
	Backdrop: {
		position: 'absolute',
		left: 0, top: 0,
		opacity: 0.6,
		backgroundColor: 'black',
		width: '100%',
		height: '100%',
	},
	Buttons: { padding: 16, flexDirection: 'row' },
})

export { S }
