import { useContext, useCallback } from 'react'
import { AssessmentContext } from '../AssessmentContext'
import { hh } from '~/utils'
import { Text, TextInput, View } from '~/utils/Tags/react-native'
import { S } from './styles'
import { useDebouncedCallback } from '@react-hookz/web'
import { Responses } from 'ttss/Taxonomy/Responses'

interface Notes {
	[itemId: string]: string
}
const GLOBAL = 'global'
export const Notes = hh(
	() => {
		const { assessment, save } = useContext(AssessmentContext)
		const setNotes = useDebouncedCallback((notes: Notes) => {
			const globalResponse = assessment.getResponse(GLOBAL) || Responses.Notes.create({})
			const item = { id: GLOBAL }
			const value = globalResponse.value ?? {}
			const newResponse = { ...globalResponse, value: { ...value, ...notes } }
			// console.log('setting notes: ', JSON.parse(JSON.stringify(newResponse)))
			assessment.setResponse(GLOBAL, newResponse)
			save(item, { autoAdvance: false })
		}, [assessment, save], 10, 50)

		const globalResponse = assessment.getResponse(GLOBAL) ?? {}
		const clientNotesValue = globalResponse.value?.clientNotes ?? ''
		const residenceNotesValue = globalResponse.value?.residenceNotes ?? ''

		return View(S.NotesView, [
			Text(S.NotesHeader, [ 'General Notes about this client' ]),
			TextInput(S.NotesInput, {
				onChangeText: (clientNotes: string) => setNotes({ clientNotes }),
				multiline: true,
				defaultValue: clientNotesValue,
			}),
			Text(S.NotesHeader, ['General Notes about this Residence']),
			TextInput(S.NotesInput, {
				onChangeText: (residenceNotes: string) => setNotes({ residenceNotes }),
				multiline: true,
				defaultValue: residenceNotesValue,
			}),
		])
	},
	'Notes',
)
