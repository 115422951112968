import { useMemo, useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { hh, pre } from '~/utils/Tags/hh'

import { getDiagnostics } from './diagnostics'
import { View, Text, Pressable } from '~/utils/Tags/react-native'
import { v4 as uuid } from 'uuid'
import { Styling, useToggler } from '~/utils'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '~/Assessments/globals'
import { ResetHESTIA } from './ResetHESTIA'
import { IssueReporter } from './IssueReporter'

const S = Styling({
	Screen: {
		backgroundColor: SECONDARY_COLOR,
		// flex: 1,
		width: '100vw',
		height: '100vh',
		overflow: 'auto',
		maxWidth: '100vw',
		alignItems: 'center',
	},
	view: {
		margin: '2em',
		border: '1px solid red',
		borderRadius: '5px',
		padding: '1em',
		backgroundColor: PRIMARY_COLOR,
		maxWidth: '100%',
	}
})

export const TroubleShooter = hh(({ error, resetErrorBoundary }: FallbackProps) => {
	const diagnostics = useMemo(getDiagnostics, [])
	const [showDetails, toggleShowDetails] = useToggler(false)

	return View(S.Screen, [
		View(S.view, { role: 'alert' }, [
			Text({ style: { fontSize: 25, fontWeight: 'bold', color: 'red' } }, ['Error']),
			Text(['Something went wrong:' ]),
			View({ style: { backgroundColor: '#ffbabd', overflow: 'auto', border: '1px solid rgb(144, 94, 101)', borderRadius: 8, padding: 10 } }, [
				Text([ 'Message: ', error?.message ?? error ]),
				Pressable({ onPress: toggleShowDetails, style: { position: 'absolute', right: 0, bottom: 0, padding: 3, outline: '1px solid black', alignSelf: 'flex-end', borderRadius: 4, float: 'right'}}, [ Text([ showDetails ? 'Hide Details': 'Show Details' ]) ]),
				showDetails && pre([ error?.stack ]),
			]),
			Gap(),
			IssueReporter({ uuid: Date.now(), diagnostics, error }),
			Gap(),
			ResetHESTIA(),
		])
	])
})

const Gap = hh(({ size = 10, style = {} }) => View({ style: { height: size, width: size, ...style }}))
const onError = (error: Error, info: { componentStack: string }) => { }
const onReset = () => {
	// clear cookies
	// clear storage
	// reload
}





