import { createContext, useState, useMemo } from 'react'
import { TaxonomyInstance, TreePath } from '~/interfaces'
import { AssessmentInstance } from 'ttss'
import { hh } from '~/utils'

export interface SubmitOptions {
	multiple?: boolean,
	autoAdvance?: boolean,
	save?: boolean
}

export interface AssessmentContext {
	assessment: AssessmentInstance & { toJSON(): any }
	taxonomy: TaxonomyInstance
	// current?: TaxonomyInstance
	// currentPath?: TreePath
	save(): void,
	newAssessment(): void
	load(assessment: any): void
	// save(item: TaxonomyInstance | Pick<TaxonomyInstance, 'id'>, options?: SubmitOptions): boolean
	// submit(item: TaxonomyInstance, options: SubmitOptions): boolean
}
export const AssessmentContext = createContext<AssessmentContext>(null as any)
export const AssessmentProvider = hh(AssessmentContext.Provider)

// const createContextState = (initialState: AssessmentContext) => {
//   const [ { assessment, taxonomy }, setState ] = useState(initialState)
//   const context = useMemo(
//     () => ({ assessment, taxonomy }) as const,
//     [ assessment, taxonomy ]
//   )
//   return context
// }
