import { View } from '~/utils/Tags'
import { Styling, } from '~/utils'
import { getQuestionText, ItemTitle } from '~/Item/Prompt'
import { ItemHeadingProps } from './interfaces'

export const S = Styling({
	Heading: {
		flexDirection: 'row',
		alignItems: 'center',

		maxWidth: '70ex',
		textAlign: 'left',
		flexShrink: 1,

		//paddingRight: '6ex',
	},
})
export const DefaultItemHeading = ({item}: ItemHeadingProps) =>
	View(S.Heading, [ ItemTitle([getQuestionText(item).text]) ])
