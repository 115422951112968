import { save as saveAssessment, load as load2, Assessment as AssessmentModel } from 'ttss'
import { useState, useMemo, useEffect, useCallback, createContext } from 'react';
// import logo from '~/./logo.svg';
import './App.css';
import { hh } from './utils/Tags'
import { View } from './utils/Tags/react-native'
import { Assessment } from './Assessments'
import { h, useToggler, applyPartialPatch, loadAssessment, useHandler } from '~/utils'
import { useLocalStorageValue } from '@react-hookz/web'
import { SnapshotIn, addMiddleware } from 'mobx-state-tree'

import { TroubleShooter } from '~/ErrorReporting/TroubleShooter'
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary'
import { useShowErrorCount } from '~/ErrorReporting/useShowErrorCount'

import './initialization'
import { EventProvider, EventSystem } from './EventSystem';
import * as TE from 'fp-ts/TaskEither'
import { pipe } from 'fp-ts/function'
// const initializeMiddlewares = (assessment) => [
//	addMiddleware(assessment, (call, next) => {
//		const { context, type, name, args } = call
//		const { id, heading } = context
//		if (!["afterAttach"].includes(name))
//			console.log(`(${id})${heading}:${type} ${name}`, ...args)
//		next(call)
//	}, true)
// ]

const events = EventSystem.create('Assessment')
events.subscribeAll({
	// Used to help with debugging in the console
	AssessmentLoaded: (assessment) => { global.assessment = assessment },
})

const EventContextValue = { value: events }
const ErrorBoundary = hh(_ErrorBoundary, 'ErrorBoundary')

const useStoredAssessment = () => {
	const [ storedDraft, storeDraft ] = useLocalStorageValue('draft', '')
	return [ storedDraft, storeDraft ] as const
}

const App = hh(
	() => {
		useShowErrorCount()
		// const eventSystem = useMemo(() => EventSystem.create(), [])
		const [ storedDraft, storeDraft ] = useStoredAssessment()
		const [ initialAssessment, setInitialAssessment ] = useState()
		const [ assessment, setAssessment ] = useState(initialAssessment)

		useEffect(() => { events.runAction('LoadAssessment', storedDraft) }, [])
		const newAssessment = useHandler(() => events.makeAction('NewAssessment')())
		// (newAssessment: SnapshotIn<typeof AssessmentModel>)
		const load = useHandler(events.makeAction('LoadAssessment'))
		const save = useHandler(() => events.runAction('SaveAssessment', assessment.serialize()))

		useEffect(
			() => {
				return events.subscribeAll({
					NewAssessment: events.makeAction('LoadAssessment'),
					// beforeLoadAssessment: // if unsaved ask to save
					// TODO: Convert loadAssessment to TaskEither
					LoadAssessment: events.chainEvent(loadAssessment, 'AssessmentLoaded' ),
					// flow(loadAssessment, emit('AssessmentLoaded))
					AssessmentLoaded: setAssessment,
					SaveAssessment: events.chainEvent(storeDraft, 'AssessmentSaved'),
				})
			}, [storeDraft])

		if (!assessment) return h('span', ["Loading"])

		return ErrorBoundary({
			FallbackComponent: TroubleShooter,
			// resetKeys: [ storedDraft, assessment, initialAssessment ],
		}, [
			EventProvider(EventContextValue, [
				View({ style: { height: '100vh' } }, [
					Assessment({ assessment, newAssessment, save, load }),
				])
			])
		])
	},
	'App',
)
App.whyDidYouRender = false
export default App;


// import * as f from "fp-ts/function";
// import * as T from "fp-ts/Task";
// import * as A from "fp-ts/Array";
// const tasks: Array<Task<string>> = [task1, task2];
// await f.pipe(tasks, A.sequence(T.ApplicativeSeq))(); // Runs in sequence


		// useEffect(() => {
		//	initializeMiddlewares(assessment)
		// }, [assessment])
		// const newAssessment = useCallback(() => {
		//	loadAssessment().then(setAssessment)
		// }, [])
