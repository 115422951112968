import { observer } from 'mobx-react'
import { QCode } from 'ttss/Taxonomy/utils'
import { HComponent, TaxonomyInstance } from '~/interfaces'
import { ItemControlsProps, ItemContainerProps, ItemEditorProps, ItemFieldProps, ItemLayoutProps, ItemOutlineProps, ItemReportProps } from './interfaces'
import { hh, View } from '~/utils/Tags'

import { DefaultItemContainer } from './Container'
import { DefaultItemControls } from './Controls'
import { DefaultItemLayout } from './Layout'
import { DefaultItemReport } from './Report'
import { DefaultItemOutline } from './Outline'
import { DefaultItemEditor } from './Editor'
import { ItemRegistry } from '~/ItemTypes'
import { DefaultItemHeading } from './Heading'

const DefaultItemField = () => {
	return null
}

export interface ItemTypeComponents {
	field?: ItemFieldProps
	container?: ItemContainerProps
	layout?: ItemLayoutProps
	report?: ItemReportProps
	editor?: ItemEditorProps
	outline?: ItemOutlineProps
	controls?: ItemControlsProps
}

export interface ItemTypeFactoryConfig extends ItemTypeComponents {
	name: string
}

export class ItemType {
	// public dataType: any
	public name: string
	public Field: HComponent<ItemFieldProps>
	public Heading: HComponent<ItemHeadingProps>
	public Container: HComponent<ItemContainerProps>
	public Layout: HComponent<ItemLayoutProps>
	public Report: HComponent<ItemReportProps>
	public Editor: HComponent<ItemEditorProps>
	public Outline: HComponent<ItemOutlineProps>
	public Controls: HComponent<ItemControlsProps>
	public options: any

	public static registry: ItemRegistry

	// onRegister() {
	//
	// }
	static create(config: ItemTypeFactoryConfig) {
		// onRegister
		return new ItemType(config.name, config)
	}

	constructor(name: string, components: ItemTypeComponents) {
		// onRegister
		this.name = name
		this.Field = hh(observer(components.field ?? DefaultItemField), name + '-Field')
		this.Heading = hh(observer(components.heading ?? DefaultItemHeading), name + '-Container')
		this.Container = hh(observer(components.container ?? DefaultItemContainer), name + '-Container')
		this.Layout = hh(observer(components.layout ?? DefaultItemLayout), name + '-Layout')
		this.Report = hh(observer(components.report ?? DefaultItemReport), name + '-Report')
		this.Editor = hh(observer(components.editor ?? DefaultItemEditor), name + '-Editor')
		this.Outline = hh(observer(components.outline ?? DefaultItemOutline), name + '-Outline')
		this.Controls = hh(observer(components.controls ?? DefaultItemControls), name + '-Controls')
	}

	static render = (item: TaxonomyInstance) => {
		const isInGroup = item.ancestors.find(node => node.Type === QCode.Group)
		const isGroup = item.Type === QCode.Group
		return (item.pageItems.length > 1) && !isInGroup && !isGroup
			? ItemType.renderMultiple(item)
			: ItemType.renderSingle(item)
	}

	static renderSingle = (item: TaxonomyInstance) => {
		const renderer = ItemType.registry.lookup(item.Type)
		console.log('RenderSingle:', item.Type)
		return [
			renderer.Container({ item, key: item.id }, [
				renderer.Layout({ item })
			]),
		]
	}

	static renderMultiple = (collectionItem: TaxonomyInstance) => {
		return collectionItem.pageItems.map(item => {
			const renderer = ItemType.registry.lookup(item.Type)
			return renderer.Container({ item, key: item.id }, [
					renderer.Layout({ item })
				])
			})
	}
}
