import { QCode } from 'ttss/Taxonomy/utils'
import { ItemType } from '~/Item'

import { observer } from 'mobx-react'
import { compose } from 'ramda'
import { hh } from '~/utils'
import { Text } from '~/utils/Tags/react-native'
import { IItemProps } from '~/interfaces'
import { PredictionAutoFill } from './AutoFill'
import { Checkbox } from './Checkbox'
import { CustomOptions } from './CustomOptions'
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion'
import { Radio } from './Radio'
import { Scorable } from './Scorable'
import { MultipleChoiceItemType } from 'ttss/Taxonomy/MultipleChoiceItem'
import { Button } from './Button'
const makeMultipleChoiceQuestion = compose(
	// hh,
	// observer as any,
	PredictionAutoFill, // (component<Option>) =>  PredictionAutoFillProps => element<option>
	CustomOptions, // component<option> =>  CustomOptionQuestionProps => element<customOption>
	MultipleChoiceQuestion, // component<option> => McqIteem&custom&auto => element
)

export const ScoreableField = compose(
	PredictionAutoFill, // (component<Option>) =>  PredictionAutoFillProps => element<option>
	MultipleChoiceQuestion, // component<option> => McqIteem&custom&auto => element
)(Button, {
	inline: true,
	isSelected: (option, idx, item) => item.isSelected(idx),
	select: ((item: any) => (_option: any, idx: number, item, select) => () => {
		item.selectOption(idx)
		select(item.next())
		// submit(item)
	})
})
// export const Scoreable = makeMultipleChoiceQuestion(Button)
export const SingleSelect = makeMultipleChoiceQuestion(Radio, {
	select: ((item: any) => (option: any, idx: number, item, select) => () => {
		item.selectOption(option)
		select(item.next())
	})
})
export const MultiSelect = makeMultipleChoiceQuestion(Checkbox)

type MultipleChoiceItemProps = IItemProps<MultipleChoiceItemType>
export const MultipleChoiceField =
	(props: MultipleChoiceItemProps) => {
		const { config: { isSingleSelect, isRadio } } = props.item
		return isRadio
			? SingleSelect(props)
			: MultiSelect(props)
	}

export const MultipleChoiceItem = ItemType.create({ name: QCode.MultipleChoice, field: MultipleChoiceField})
export const ScoreableItem = ItemType.create({ name: QCode.Scoreable, field: ScoreableField })
