import { useEffect, useMemo, useRef, useCallback, useState, forwardRef, MutableRefObject, ComponentType } from 'react'
import { difference } from 'ramda'
import {
	ActivityIndicator as activityIndicator,
	Button as button,
	CheckBox as checkBox,
	Image as image,
	// ImageUpload as imageUpload,
	Linking,
	// ListView as listView,
	Animated,
	PanResponder,
	Picker as picker,
	ProgressBar as progressBar,
	ScrollView as scrollView,
	StyleSheet,
	Switch as Switch_,
	Text as text,
	TextInput as textInput,
	TouchableOpacity as touchableOpacity,
	Pressable as pressable,
	TouchableWithoutFeedback as touchableWithoutFeedback,
	View as view,
} from 'react-native-web'
import { h, hh } from './hh'
import { useHandler } from '~/utils/useHandler'

export const Button = hh(button, 'button')

const updateClasses = (
	classesToRemove: string[],
	wantedClasses: string[],
	previouslyAppliedClassesRef: MutableRefObject<string[]>,
	element?: HTMLInputElement,
) => {
	if (element) {
		// force our unwanted classes off of the element
		classesToRemove.forEach(className => element.classList.toggle(className, false))

		// Force our wanted classes on to the element
		wantedClasses.forEach(className => element.classList.toggle(className, true))

		// Update
		previouslyAppliedClassesRef.current = wantedClasses
	}
}

const withRef = <Props>(component: ComponentType<Props>) => {
	const displayName = (component?.displayName ?? 'Missing') + '-WithCssRef'
	const Component = hh(component)
	return hh(
		forwardRef((props: Props, passedRef) => {
			const classNames: string | undefined = props.className
			const wantedClasses = useMemo(() => classNames?.split(' ') ?? [], [classNames])
			const elementRef = useRef<HTMLElement>()

			// Find all classes that were previously applied, but no longer wanted
			// this is needed to keep track of which classes have been applied by the
			// parent as opposed to another source and thus are safe to remove
			const previouslyAppliedClassesRef = useRef<string[]>([])
			const previouslyAppliedClasses = previouslyAppliedClassesRef?.current

			const fixElementClasses = useHandler(() => {
				const element = elementRef.current
				if (element) {
					const classesNoLongerNeeded = difference(previouslyAppliedClassesRef.current, wantedClasses)

					const elementClasses = [...element.classList.values()]
					const classesToAdd = difference(wantedClasses, elementClasses)
					const classesToRemove = difference(classesNoLongerNeeded, elementClasses)
					if ((classesToAdd.length > 0 || classesToRemove.length > 0)) {
						// console.groupCollapsed(`Fixing classes: =[${element.classList}] +[${classesToAdd}]  -[${classesToRemove}]`)
						// console.log('To add:', classesToAdd)
						// console.log('To remove:', classesToRemove)
						// console.log('Wanted:', wantedClasses)
						// console.log('PreviouslyApplied:', previouslyAppliedClasses)
						// console.log('Element classes:', elementClasses)
						// console.groupEnd()
						updateClasses(classesToRemove, wantedClasses, previouslyAppliedClassesRef, element)
					}
				}
			})

			// const fixElementClasses = useCallback(() => {
			//	const element = elementRef.current
			//	if (element) {
			//		const classesNoLongerNeeded = difference(previouslyAppliedClassesRef.current, wantedClasses)

			//		const elementClasses = [ ...element.classList.values() ]
			//		const classesToAdd = difference(wantedClasses, elementClasses)
			//		const classesToRemove = difference(classesNoLongerNeeded, elementClasses)
			//		if ((classesToAdd.length > 0 || classesToRemove.length > 0)) {
			//			// console.groupCollapsed(`Fixing classes: =[${element.classList}] +[${classesToAdd}]  -[${classesToRemove}]`)
			//			// console.log('To add:', classesToAdd)
			//			// console.log('To remove:', classesToRemove)
			//			// console.log('Wanted:', wantedClasses)
			//			// console.log('PreviouslyApplied:', previouslyAppliedClasses)
			//			// console.log('Element classes:', elementClasses)
			//			// console.groupEnd()
			//			updateClasses(classesToRemove, wantedClasses, previouslyAppliedClassesRef, element)
			//		}
			//	}
			// }, [wantedClasses, previouslyAppliedClasses])

			const mutationObserverRef = useRef<MutationObserver>()
			const setupMutationObserver = useCallback(() => {
				const element = elementRef.current
				if (mutationObserverRef.current) mutationObserverRef.current.disconnect()
				if (element) {
					const mutationObserver = new MutationObserver(fixElementClasses)
					mutationObserverRef.current = mutationObserver
					mutationObserver.observe(element, { attributes: true })
				}
			}, [fixElementClasses])

			useEffect(() => () => {
				console.log('Unmounting', Component.name)
				if (mutationObserverRef.current) mutationObserverRef.current.disconnect()
				previouslyAppliedClassesRef.current.forEach(className => elementRef.current?.classList.toggle(className, false))
			}, [])

			const ref = useCallback(node => {
				if (node !== elementRef.current) {
					elementRef.current = node
					fixElementClasses()
					setupMutationObserver()
				}
			}, [fixElementClasses, setupMutationObserver])

			const memoedProps = useMemo(() => ({ ...props, ref }), [props, ref])
			return Component(memoedProps, props.children)
		}),
		displayName,
	)
}

export const AnimatedView = withRef(Animated.View)
export const AnimatedFlatList = withRef(Animated.FlatList)
export const AnimatedImage = withRef(Animated.Image)
export const AnimatedText = withRef(Animated.Text)
export const AnimatedScrollView = withRef(Animated.ScrollView)
export const AnimatedSectionList = withRef(Animated.SectionList)
export const View = withRef(view)
export const Text = withRef(text)
export const TextInput = withRef(textInput)
export const Image = withRef(image)
export const ScrollView = withRef(scrollView)
// export const ListView = withRef(listView)
export const ProgressBar = withRef(progressBar)
export const TouchableOpacity = withRef(touchableOpacity)
export const TouchableWithoutFeedback = withRef(touchableWithoutFeedback)
export const Switch = withRef(Switch_)
export const ActivityIndicator = withRef(activityIndicator)
// export const ImageUpload = withRef(imageUpload)
export const Picker = withRef(picker)
export const PickerItem = withRef(picker.Item)
// export const PickerItem = forwardRef(withRef(picker.Item))
export const CheckBox = withRef(checkBox)
export const Pressable = withRef(pressable)

export {
	StyleSheet,
	PanResponder,
	Linking,
}
