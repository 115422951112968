import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Instance } from 'mobx-state-tree'

import { Attachment } from 'ttss/Taxonomy/AttachmentItem/response'
import { hh } from '~/utils'
import { HComponent } from '~/interfaces'

export interface IAttachmentViewProps {
	attachment: Instance<typeof Attachment>
	component: HComponent<{ filename: string }>
}

/**
 * View an existing attachment.
 */
export const PhotoAttachmentView = hh(observer(
	(props: IAttachmentViewProps) => {
		const { component, attachment, extra = {} } = props
		return component({
			fileName: useAttachmentSrc(attachment),
			...extra
		})
	}),
	'PhotoAttachmentView'
)

export const useAttachmentSrc = (attachment: Instance<typeof Attachment>) => {
	const [ objectURL, setObjectUrl	] = useState<string>()

	useEffect(
		() => {
			if (attachment?.file) setObjectUrl(URL.createObjectURL(attachment.file))
			return () => {
				if (objectURL) URL.revokeObjectURL(objectURL)
			}
		}, [attachment, attachment.file])

	return objectURL
}
