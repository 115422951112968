import { hh } from "~/utils"
import { fileSrc } from "~/utils/assets"
import { Image, Text } from '~/utils/Tags/react-native'
import { getFormValues, ICollectionRendererOptions } from "./utils"
import { IPhoto } from '~/interfaces'
import { S } from './styles'

import { observer } from 'mobx-react'
export const TeamMember = hh(observer(
	(props: ICollectionRendererOptions) => {
		const response = getFormValues(props.item)
		const image = response['Image(optional)'] as IPhoto

		return [
			!!image && Image(S.ItemIcon, fileSrc(image.src)),
			Text([response['First Name'], ' ', response['Last Name']]),
		]
	}),
	'TeamMember',
)
