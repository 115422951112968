import { hh, Styling, useToggler } from '~/utils'
import { TextInput, Text, View, TouchableOpacity, Image } from '~/utils/Tags/react-native'
import { TreePathItems, TaxonomyInstance } from '~/interfaces'
import { AssessmentContext } from '~/Assessments/AssessmentContext'
import { useNavigationContext } from '~/Assessments/useTaxonomyNavigation'
import { IItemProps } from '~/interfaces'

export interface ICommentFieldProps extends IItemProps { }
export const CommentField = hh(
	({ item, style = {} }: ICommentFieldProps) => {
		// submit: AssessmentContext['save']
		const response = item.response ?? {}
		const setComment = (comment: string) => {
			item.response = { ...item.response, comment }
		}

		return View(S.CommentField, [
			Text([ 'Comment' ]),
			TextInput(S.CommentInput, {
				key: item.id + '_comment',
				onChangeText: setComment,
				multiline: true,
				numberOfLines: 4,
				defaultValue: response.comment ?? ''
			})
		])
	},
	'CommentField'
)

const S = Styling({
	CommentInput: {
		backgroundColor: '#E8E8E8',
		flex: 1,
		padding: '1ex',
		width: '60ex',
		color: 'black',
	},
	CommentField: {
		// border: '1px solid gray',
		padding: '1ex',
		// borderRadius: '0.4ex',
		marginTop: '.5ex',
	},
})
