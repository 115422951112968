import { QCode } from 'ttss/Taxonomy/utils'
import { ItemType } from '~/Item'
import { View } from '~/utils/Tags/react-native'

const HorizontalSparatorStyle = {
	style: {
		height: 3,
		width: '100%',
		backgroundColor: 'black',
		marginTop: 10,
		marginBottom: 10,
	},
}

export const HorizontalSeparatorItem = ItemType.create({
	name: QCode.HorizontalSeparator,
	heading: () => null,
	field: () => View(HorizontalSparatorStyle)
})
