import { observer } from 'mobx-react'
import { hh } from '~/utils'
import { TaxonomyInstance, TreeNode, TreePath, TreePathItems } from '~/interfaces'

import { front, last, maybe } from '~/utils'
import { QCode }from 'ttss/Taxonomy/utils'
import { AssessmentInstance } from 'ttss'

// export function getPrediction(
// 	selected: IAssessment,
// 	taxonomyItem: TaxonomyInstance,
// 	taxonomy: TaxonomyInstance,
// ) {
// 	const response = selected.getResponse(taxonomyItem.id) || {}
// 	const { matrixKeys } = taxonomyItem
// 	let recommended = -1
// 	if (matrixKeys) {
// 		// TODO: switch to monads. Currently 'matrixKey' is needed for error reporting
// 		// monads would allow the error to propagate up to where `matrixKey` is still in scope
// 		// const items = matrixKeys
// 		// 	.map((matrixKey) => MATRICES[matrixKey])
// 		// 	.flatMap((matrix) => { })
// 		const items = matrixKeys.flatMap((matrixKey: string) => {
// 			const matrix = MATRICES[matrixKey]
// 			const predictHeading = toLowerCase(taxonomyItem.heading)
// 			// Make sure this item is predictable
// 			if (!matrix || !matrix.predictables || !matrix.predictables.hasOwnProperty(predictHeading)) {
// 				throw new Error(`Item: ${taxonomyItem.id}(${predictHeading}) does not exist on: ${matrixKey}`)
// 			}
// 			const predictingIdx = matrix.predictables[predictHeading]
// 			const predictorItems = getPredictorItems(matrix, taxonomy)
// 			const scoresByHeading = getScoresOfPredictors(selected, predictorItems)
// 			return getPredictors(predictorItems, scoresByHeading, matrix, predictingIdx)
// 		})
// 		recommended = Math.max(...items)
// 	}
// 	return recommended
// }

// function getScoresOfPredictors(assessment, predictorItems) {
// 	const scoresByHeading = {}
// 	for (const item of predictorItems) {
// 		const itemResponse = assessment.getResponse(item.id)
// 		const responseValue = itemResponse && Array.isArray(itemResponse.value)
// 			? itemResponse
// 			: { value: [0] }
// 		scoresByHeading[toLowerCase(item.heading)] = responseValue.value[0]
// 	}
// 	return scoresByHeading
// }

// function getPredictors(predictorItems, scoresByHeading, matrix, predictingIdx) {
// 	return predictorItems
// 		.map(item => toLowerCase(item.heading))
// 		.map((heading: string) => {
// 			const headingScore = scoresByHeading[heading]
// 			const predictorIdx = matrix.predictors[heading]
// 			return headingScore ? parseInt(matrix.data[predictingIdx][predictorIdx], 10) : 0
// 		})
// }
// function getPredictorItems(matrix, taxonomy) {
// 	const predictorHeadings = Object.keys(matrix.predictors).map(toLowerCase)
// 	const predictorItems = findTaxonomyItems(
// 		(item: TaxonomyInstance) => predictorHeadings.includes(toLowerCase(item.heading)),
// 		taxonomy,
// 	)
// 	return predictorItems
// }

// export function ProxyAssessment(data: IAssessmentResponses) {
// 	const _data = typeof data === 'object' ? { ...data } : {}
// 	const getResponse = (id: string) => _data['' + id]

// 	const setResponse = (id: string, newValue: IAssessmentResponse<ICollectionItem>) =>
// 		({ ..._data, ['' + id]: { value: newValue} })
// 	const setResponses = (newResponses: IAssessmentResponses) => ({
// 		..._data,
// 		...ObjectFromEntries(Object.entries(newResponses).forEach(
// 			([id, newResponse]) => ({ ['' + id]: { ...getResponse(id), ...newResponse } }),
// 		)),
// 	})
// 	return {
// 		data() { return { ..._data } },
// 		getResponse,
// 		setResponse,
// 		setResponses,
// 	}
// }

// TODO: Array.includes polyfill
// export function toggleArrayItem(array: any[] = [], el: any) {
// 	return (
// 		array.includes(el)
// 			? array.filter(value => value !== el)
// 			: [...array, el]
// 	)
// }

// export function getOption(option: string | string[]) {
// 	if (typeof option === 'string') {
// 		return { name: option }
// 	} else if (option && option.length) {
// 		const name = option[0] || ''
// 		const description = option.length > 1 && option[1]
// 		return { name, description }
// 	}
// 	return { name: '', description: '' }
// }

// import { debounce } from 'lodash'

export function itemHasChildren(item: TaxonomyInstance) {
	return !!item.items && item.items.length > 0
}

export const back = maybe(<T>(array: T[]) => array.slice(1, array.length), [])

// export const getAssessment = <T extends TaxonomyInstance>(
// 	stores: Stores,
// 	props: Partial<IItemProps<T>>,
// ) => ({
// 	rootAssessment: stores.assessments.selected || {},
// 	assessment: props.assessment,
// 	taxonomy: get(stores, ['taxonomies', 'data', 0, 'data'], {}),
// })

// const extendProps = (fn) => (WrappedComponent) =>
// 	class extends React.Component {
// 		public shouldComponentUpdate(nextProps, nextState) {
// 			return objEquals(this.props, nextProps)
// 		}
// 		public render() {
// 			return hh(observer(WrappedComponent))({...this.props, ...fn(this.props)})
// 		}
// 	}

// const extendProps = (fn) => hh(observer(
// 	(WrappedComponent) => hh(WrappedComponent)({...this.props, ...fn(this.props)})
// ))

export function arrayEquals(arr1: any[], arr2: any[]) {
	if (arr1.length !== arr2.length) { return false }
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) { return false }
	}
	return true
}

export const pathStartsWith = (path1: TreePath, path2: TreePath) =>
	arrayEquals(path1.slice(0, path2.length), [...path2])

export const itemIsPageType = ({ Type, heading }: TaxonomyInstance) =>
	(Type === QCode.Group || Type === QCode.Collection)

export const anscestorIsPage = (items: TreePathItems) => front(items, []).find(itemIsPageType)

export const itemIsSelectable =
	(item: TaxonomyInstance) => item.heading && item.heading.length && !itemIsPageType(item)

// the element preceding the last element
export const getParent = <T>(items: T[]) => items[items.length - 2]
export const getSiblings = (items: TaxonomyInstance[]) => getParent(items).items

export function isSamePage(currentPath: TreePathItems, candidatePath: TreePathItems) {
	return (
		((siblingsAreLeafNodes(currentPath) && siblingsAreLeafNodes(candidatePath))
		|| (anscestorIsPage(currentPath) && anscestorIsPage(candidatePath)))
			&& getParent(currentPath) === getParent(candidatePath)
	)
}

export const itemIsCollection =
	(items: TaxonomyInstance[]) => !itemIsSelectable(last(items)) && siblingsAreLeafNodes(items)

export function itemOrChildren(
	item: TaxonomyInstance,
	showChildren: boolean,
	selectedPathItems: TaxonomyInstance[],
) {
	const children = item.items || [item]
	return (showChildren && !selectedPathItems.find(itemIsPageType)) ? children : [item]
}

// FIXME: `maybe` typing needs variadic types: TypeScript/issues/5453
export const getScore = maybe(
	(item, assessment) => assessment.getResponse(item.id).value,
	undefined,
)

export const itemIsScored = maybe(
	(item, assessment) => !!getScore(item, assessment),
	false,
)

// export function ancestorScoredIrrelevant(items: TreePathItems, assessment: AssessmentInstance) {
// 	return front(items, []).find((item: TaxonomyInstance) => {
// 		if (item.Type === QType.Group || item.Type === QType.Collection) { return true }
// 		const score = getScore(item, assessment)
// 		if (!score) { return false }

// 		const ancestorIsScored0 = item.Type === QType.MultipleChoice && item.config.isSingleSelect && score[0] !== 1
// 		return ancestorIsScored0
// 	})
// }

export const isInSelection =
	(path: TreePath, selected: TreePath) => pathStartsWith(selected, path)

export const isExactSelection = (path: TreePath, selected: TreePath) => arrayEquals(path, selected)

export function areLeafNodes(items: TaxonomyInstance[]) {
	return !items.find(itemHasChildren)
}

export function childrenAreLeafNodes(item: TaxonomyInstance) {
	return !!item.items && !item.items.find(itemHasChildren)
}

export function siblingsAreLeafNodes(items: TaxonomyInstance[]) {
	return areLeafNodes(getSiblings(items))
}

export function parentPath(path: TreePath) {
	return path.slice(0, path.length - 1)
}

export function areSiblings(path1: TreePath, path2: TreePath) {
	return arrayEquals(parentPath(path1), parentPath(path2))
}

export function _getPathItems(path: TreePath, taxonomy: TreeNode): TreeNode[] {
	const [next, ...rest] = path
	if (!taxonomy || !Number.isInteger(next)) { return [] }
	if (!taxonomy.items) { return [taxonomy] }
	const nextChild = taxonomy.items[next]
	return [ nextChild, ..._getPathItems(rest, nextChild) ]
}

/**
 * Map each level in a path to the corresponding element in the tree.
 * @param path - number[] addressing item from root of taxonomy to depth, including root
 * @param root - ITaxonomyInstance
 */
export function getPathItems(path: TreePath, root: TreeNode) {
	// return _getPathItems(path, taxonomy)
	return [root, ..._getPathItems(path, root)]
	// return _getPathItems(path, taxonomy.items ? taxonomy : { items: taxonomy })
}

export function getSelectedItem(path: TreePath, taxonomy: TreeNode) {
	return last(getPathItems(path, taxonomy))
}

// export function flattenChildren(taxonomy: TreeNode) {
// 	if (!taxonomy.items) { return [] }
// 	const descendants = taxonomy.items.flatMap(flattenChildren)
// 	return [taxonomy, ...descendants]
// }

export function getPathToLastDescendant(branch: TreeNode): number[] {
	const { items } = branch
	if (!items || !items.length) { return [] }
	const lastIdx = items.length - 1
	return [lastIdx, ...getPathToLastDescendant(items[lastIdx])]
}

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export function getOrdinal(depth: number, num: number) {
	const ordinals = [
		[], // the first one is
		[], //
		['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII', 'XIV', 'XV'],
		alphabet.split(''),
		['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
		['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'],
		alphabet.toLocaleLowerCase().split(''),
		['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
		alphabet.toLocaleLowerCase().split('').map((x: string) => x + x),
	]
	return ordinals[depth][num]
}

// type TaxonomySearchPredicate = (item: TaxonomyInstance) => boolean
// export function findTaxonomyInstances(predicate: TaxonomySearchPredicate, taxonomy: TaxonomyInstance) {
// 	const result = predicate(taxonomy) ? [taxonomy] : []
// 	if (!taxonomy.items) { return [] }
// 	return result.concat(
// 		taxonomy.items.flatMap((child: TaxonomyInstance) => findTaxonomyInstances(predicate, child)),
// 	)
// }

// export function findPathToItem(predicate: TaxonomySearchPredicate, taxonomy: TaxonomyInstance) {
// 	return findNext({ startingPath: [], taxonomy, predicate })
// }

// export const getQuestion = (item: TaxonomyInstance) => QUESTION_TYPES[item.Type]
// const toLowerCase = (val: string) => (val || '').toLowerCase()
// const debounceTime = timeout => (target, key, descriptor) => {
//   if (descriptor === undefined) {
//     descriptor = Object.getOwnPropertyDescriptor(target, key)
//   }

//   const originalMethod = descriptor.value
//   descriptor.value = debounce(originalMethod, timeout)
//   return descriptor
// }
