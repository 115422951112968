import { useEffect } from 'react'

/**
 * Modifies the create-react-app error reporting screen to include the number of errors.
 *
 * This makes it easier to measure progress when addressing errors. Probably only necessary when
 * making a large change that may introduce many errors.
 */
export const useShowErrorCount = () =>
	useEffect(() => {
		// Options for the observer (which mutations to observe)
		const config = { childList: true, subtree: true };
		// Create an observer instance linked to the callback function
		const reportErrors = () => {
			// Use traditional 'for loops' for IE 11
			setTimeout(
				() => {
					var iframe = document.getElementById('webpack-dev-server-client-overlay');
					var innerDoc = ((iframe as any)?.contentWindow)?.document;
					const targetNode = innerDoc?.getElementById('webpack-dev-server-client-overlay-div');

					console.log(iframe, innerDoc, targetNode)
					if (targetNode) {
						// const errors = document.createElement('span')
						const errors = (targetNode?.children?.length ?? 0) - 6
						targetNode.firstChild.textContent = targetNode.firstChild.textContent + ' ' + errors
						// console.log('inner ',innerDoc.firstChild, errors)
						// innerDoc.firstChild.appendChild(errors)
					}
				},
				300)
		}

		// reportErrors()
		const observer = new MutationObserver(reportErrors);

		// Start observing the target node for configured mutations
		observer.observe(document.body, config);

		// Later, you can stop observing
		return () => observer.disconnect();
	}, [])
