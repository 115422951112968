import { hh } from '~/utils/Tags'
import { Text, View } from '~/utils/Tags/react-native'
import type { Diagnostics } from '~/ErrorReporting/diagnostics'

interface VersionInfoProps {
	diagnostics: Diagnostics
}

export const VersionInfo = hh(
	({ diagnostics }) => {
		const { platform, browser, engine, os } = diagnostics.client
		console.log(diagnostics)
		return View([
			Text(['Version Info:']),
			Text(['UI Build Time: ', diagnostics.versions.ui ]),
			Text([ 'TTSS Build Time: ', diagnostics.versions.ttss ]),
			Text([ `Platform: ${platform.type} ${platform.vendor ?? ''} ${platform.model ?? ''}` ]),
			Text([ `OS: ${os.name} ${os.versionName ?? ''} ${os.version ?? ''}`]),
			Text([ `Browser: ${browser.name} ${browser.version ?? ''}`]),
			Text([ `Engine: ${engine.name} ${engine.version ?? ''}`])
		])
	}
)
