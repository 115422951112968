import { observer } from 'mobx-react'
import { hh, Styling, get, pureSet, range } from '~/utils'
import { Picker, PickerItem, View } from '~/utils/Tags/react-native'
import { IDate, DateRange, MONTH_NAMES, CURRENT_YEAR, YearMonthDayFromDate } from './utils'

export interface IDateInputProps<T> {
	label: string,
	selectedDate: IDate,
	style?: T
	validRange?: DateRange
	onChange(data: IDate): void
}

const FUTURE_YEAR = CURRENT_YEAR + 5
const DEFAULT_VALID_RANGE = [
	{ year: CURRENT_YEAR, month: 0, day: 1 }, // min
	{ year: FUTURE_YEAR, month: 0, day: 1 }, // max
]

export const DateInput = hh(
	observer(<T extends {}>(options: IDateInputProps<T>) => {
	const { label, selectedDate, onChange, validRange = DEFAULT_VALID_RANGE, style = {} } = options
	const minDate = validRange[0] ?? { year: CURRENT_YEAR }
	const maxDate = validRange[1] ?? { year: FUTURE_YEAR }

	const date = {
		month: 0,
		day: 0,
		...minDate,
		...selectedDate,
	}

	const min = minDate instanceof Date
			? YearMonthDayFromDate(minDate)
			: { ...minDate }

	const max = maxDate instanceof Date
			? YearMonthDayFromDate(maxDate)
			: { ...maxDate }

	return View(S.Field, { style }, [
		View(S.Parts, [
			Picker(S.MultiPart, {
				selectedValue: MONTH_NAMES[get(date, ['month'])],
				onValueChange: (_: any, idx: number) => onChange(pureSet(date, ['month'], idx)),
			}, MONTH_NAMES.map((monthName: string) => PickerItem({ label: monthName, value: monthName }))),
			Picker(S.MultiPart, {
				selectedValue: get(date, ['day']),
				onValueChange: (_: any, idx: number) => onChange(pureSet(date, ['day'], idx + 1)),
				}, range(31).map((_: any, idx: number) => PickerItem({ label: idx + 1 + '', value: idx + 1 }))),
			Picker(S.MultiPart, {
				selectedValue: get(date, ['year']),
				onValueChange: (_: any, offset: number) => onChange(pureSet(date, ['year'], min.year + offset)),
				}, range(max.year - min.year).map((_: any, offset: number) => PickerItem({ label: min.year + offset, value: min.year + offset }))),
		]),
	])
	}),
	'DateInput',
)

const S = Styling({
	Field: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	MultiPart: {
		flexDirection: 'row',
		minWidth: 0,
		marginRight: 8, padding: 4,
		backgroundColor: '#eeeeee',
		fontSize: '1em',
		borderRadius: '3px',
		border: '1px solid gray',
	},
	Parts: {
		flexDirection: 'row',
	},
})
