import * as CSS from 'csstype'
import { source, svg } from '~/utils/assets'
import { Styling } from '~/utils'
import { Image } from '~/utils/Tags/react-native'

const IMG_PLACEHOLDER = 'upload_photo.svg'

export interface ImageViewProps {
	placeholder?: string
	fileName?: string
	style?: CSS.Properties
}

export const PhotoView = ({
	fileName,
	style,
	placeholder = IMG_PLACEHOLDER,
}: ImageViewProps) => {
	const src = fileName
		? source(fileName)
		: svg(placeholder)
	const mergedStyle = style
		? { ...src.style, ...style }
		: src.style
	return Image(S.Photo, { ...src, style: mergedStyle })
}

const S = Styling({
	Photo: {
		width: 60,
		height: 60,
		marginRight: 10,
		resizeMode: 'contain',
		border: '1px solid darkgray',
		borderRadius: '3px',
	},
})
