import { Text, TouchableOpacity, View } from '~/utils/Tags/react-native'
import { hh } from '~/utils'
import { S } from './styles'
import { OptionRenderer, OptionRendererProps } from './utils'
import { observer } from 'mobx-react'

const RadioButton = (selected: boolean) =>
	View(S.RadioOuter, [!!selected && View(S.RadioSelected)])

export const Radio: OptionRenderer = hh(observer(
	(props: OptionRendererProps) => {
		const { label, showLabel, selected, select, idx, predicted } = props
		return TouchableOpacity(S.Option, { key: idx, onPress: select }, [
			RadioButton(selected),
			showLabel && Text([ label ]),
			predicted === idx && Text(S.AutoFilled, [' ← Predicted']),
		])
	}),
	'RadioOption',
)


