import { h, hh as _hh, NameOrType, createClasses, IClass } from 'typerscript'
export const Styling = <T extends IClass>(styles: T) => createClasses<T>(styles)
export { h }
// export const hh = <P, T extends NameOrType<P, T>>(component: T, name?: string): any => {
// 	const c = _hh(component);
// 	(c as any).displayName = name
// 	return c
// }
export const hh = _hh

const elements = [
	'div', 'html', 'meta', 'section', 'header', 'p', 'button', 'pre', 'textarea',
	'head', 'base', 'body', 'link', 'main', 'style', 'script', 'title',
	'form', 'input', 'img', 'canvas',
	// The following are imported from the svgs package instead
	// 'Svg', 'Circle', 'Rect',
]
const [
	div, html, meta, section, header, p, button, pre, textarea,
	head, base, body, link, main, style, script, title,
	form, input, img, canvas,
	// The following are imported from the svgs package instead
	// Circle, Rect, Svg,
] = elements.map(v => hh(v, v))

export {
	header,
	html,
	head,
	base,
	body,
	div,
	form,
	meta,
	section,
	link,
	main,
	style,
	script,
	title,
	input,
	img,
	canvas,
	pre,
	p,
	button,
	textarea,
	// The following are imported from the svgs package instead
	// Svg, Circle, Rect,
}
