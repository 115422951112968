import { getOrdinal } from '../utils'
import { arrayOfLength } from '~/utils'

import { props } from 'ramda'
interface tsvFromObj<T extends object> {
	headers: (string | number | null | undefined)[]
	data: Record<string,T>[]
	fields: (keyof T)[]
}
const tsvFromObj = <T extends object>({ headers, fields, data }: tsvFromObj<T>) => {
	const getRow = (field: Record<string, T>) => props(fields as any)(field).join('\t')
	const rows = data.map(getRow)
	return [
		headers.join('\t'),
		...rows,
	]
	.join('\n')
}

export const exportTaxonomy = (taxonomy) => {
	let maxDepth = 0
	let next = taxonomy
	do {
		// rows.push(createRow(next))
		if (maxDepth < next.depth) maxDepth = next.depth
		next = next.iterateNext(next)
	} while (next)

	const rows = [[
		...arrayOfLength(maxDepth),
		,// getOrdinal(depth, selfIndex),
		, //heading,
		'Type',
		'Prompt',
		'Description',
		'answer',
		'time',
		'Options',
	].join('\t')]
	next = taxonomy
	do {
		rows.push(createRow(next, maxDepth).join('\t'))
		next = next.iterateNext(next)
	} while (next)

	return rows.join('\n')
}

const createRow = (item, maxDepth) => {
	const {
		id,
		Type,
		heading,
		Name,
		icon,
		prompt,
		description,
		inline,
		matrixKeys,
		predicate,
		children,
		branchWhen,
		score,
		depth,
		response,
		options,
		typeName,
		selfIndex,
	} = item
	return [
		...arrayOfLength(depth),
		score,
		getOrdinal(depth, selfIndex),
		heading,
		...arrayOfLength(maxDepth - depth),
		typeName,
		prompt,
		description,
		score,
		response?.value,
		response?.updated,
		options,
		// config.
	]
	// relevant
}

// id
// Type
// heading
// Name
// icon
// prompt
// description
// inline
// matrixKeys
// predicate
// children
// branchWhen
// depth
// response
// score
// typeName
// outlineText
// relevant
// options
// config
