import * as CSS from 'csstype'
import { QCode } from 'ttss/Taxonomy/utils'
import { GroupItem } from 'ttss/Taxonomy/GroupItem'

import { View } from '~/utils/Tags/react-native'
import { Styling, hh, objIf } from '~/utils'
import { ItemType } from '~/Item'
import { HComponent, IItemProps } from '~/interfaces'
import { TaxonomyInstance } from '~/interfaces'
import { useNavigationContext } from '~/Assessments/useTaxonomyNavigation'
import { TERTIARY_COLOR } from '~/Assessments/globals'

const SelectedBorderWidth = 4
const SelectedStyle = {
	borderColor: 'lightblue',
	borderLeftWidth: `${SelectedBorderWidth}px`,
	paddingLeft: `${SelectedBorderWidth}px`,
	marginLeft: `-${SelectedBorderWidth * 2}px`,
}

const GroupStyle: CSS.Properties = {
	borderColor: TERTIARY_COLOR,
	borderWidth: '2px',
	borderStyle: 'solid',
}

const S = Styling({
	GroupItem: {
		flexDirection: 'column',
		flex: 1,
		justifyItems: 'flex-start',
		minHeight: 'min-content', // Needed for proper vertical spacing in chrome/safari
	},
	Group: {
		paddingTop: '1em',
	},
})

export const GroupField: HComponent<IItemProps<typeof GroupItem>> =
	(props: IItemProps<typeof GroupItem>) => {
		const { item } = props
		const { selected } = useNavigationContext()

		const children: TaxonomyInstance[] = item.items ?? []
		const siblingsLength = children.length
		return View(
			S.Group,
			children.map(child => GroupChild({ child, siblingsLength, selected })),
		)
	}

const GroupChild = hh(
	({ child, siblingsLength = 0, selected }) =>
		View(S.GroupItem, {
			// title: stringify(childItem),
			key: child.id,
			style: {
				flexDirection: child.parent.inline ? 'row' : 'column',
				flex: 1,
				...objIf(siblingsLength > 0 && child === selected, SelectedStyle),
			},
		}, [
			ItemType.render(child),
		]),
	'GroupChild',
)

const GroupContainer =
	({ }) => {
		return null
	}
export const Group = ItemType.create({
	name: QCode.Group,
	field: GroupField
}) //, GroupContainer)
