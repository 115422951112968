import { ImageResizeMode } from 'react-native'
const assetPath = '/assets/'

// const asset = (name: string) => assetPath + 'png/' + name.replace(/\.svg$/i, '.png')
export const source = (uri: string, resizeMode: ImageResizeMode = 'contain') => ({
	source: { uri },
	resizeMode,
	style: { resizeMode },
}) as const

export const asset = (name: string) => assetPath + 'svg/' + name
export const assetSrc = (uri: string) => source(asset(uri))
export const fileSrc = (fileName: string) => source('/files/' + fileName)

/**
 * Returns an object with properties to display an svg from the asset folder.
 */
export const svg = <T>(uri: string, rest: T = {} as T) => ({
	...source(asset(uri), 'contain'), // TODO: add '.svg' here
	...rest
}) as const
