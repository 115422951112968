import { useContext, useState } from 'react'
import { hh } from '~/utils'
import { TaxonomyInstance, IItemProps } from '~/interfaces'
import { HComponent } from '~/interfaces'
import { IPredictionStatus } from './types'
import { ScoreableItem } from 'ttss/Taxonomy/MultipleChoiceItem/Scoreable'
import { AssessmentContext } from '~/Assessments/AssessmentContext'
import { OptionRendererProps, PredictionProps  } from './utils'

export type PredictionAutoFillProps<T extends typeof ScoreableItem> = IItemProps<T> & PredictionProps


export const PredictionAutoFill = <T extends typeof ScoreableItem>(component: HComponent<PredictionProps & OptionRendererProps>) => {
  //const wrapped = hh(component, '-Prediction')
  return hh((props: PredictionAutoFillProps<T>) => {
		const { save } = useContext(AssessmentContext)
    const { item } = props
    const [ predictionError, setPredictionError ] = useState<IPredictionStatus['predictionError']>()
    const [ predictedOption, setPredictedOption ] = useState<IPredictionStatus['predictedOption']>()

    try {
      const prediction = item.predictedOption
      // const predictedOption = this.predictedOption = item.predictedOption
      // console.log('Prediction for scorable:', item.heading, 'is', predictedOption)
      if (prediction > -1 && !item.response?.value) {
        // console.log('submitting predicted option:', predictedOption)
        // item.select(predictedOption)

				// item.selectOption(prediction)
        // submit(item, { autoAdvance: false })
//        setPredictedOption(prediction)
      }
    } catch (e: any) {
      // setPredictionError(e.message)
    }

    return component({ ...props, predictedOption, predictionError })
  }, '${component.displayName}WPrediction')
}
