import { useContext, useState } from 'react'
import { TaxonomyInstance } from '~/interfaces'
import { useNavigationContext } from '../useTaxonomyNavigation'
import { ScrollView, Text, View } from '~/utils/Tags/react-native'
import { hh } from '~/utils'
import { TreePath } from '~/interfaces'
import { isInSelection } from '../utils'
import { OutlineItem } from './OutlineItem'
import { S } from './styles'
import { Tabs } from './Tabs'
import { AssessmentContext } from '../AssessmentContext'
import { OutlineGroup } from './OutlineGroup'

export const Outline = hh(
	() => {
		const { taxonomy } = useContext(AssessmentContext)
		const { selectedPath } = useNavigationContext()
		const selectedTab = selectedPath[0] ?? 0
		const tabRoot = taxonomy.items[selectedTab]
		if (!tabRoot?.items) {
			return View([
				Text([ 'There is no taxonomy loaded.'])
			])
		}

		return View(S.Outline, [
			Tabs({ taxonomy, selected: selectedTab }),// remove props => useContext in Tabs
			ScrollView(S.OutlineList, { color: 'black' },
				OutlineGroup(tabRoot.items)
			),
		])

	},
	'Outline',
)
