import { QCode } from 'ttss/Taxonomy/utils'
import { ItemType, ItemLayoutRenderer, ItemFieldRenderer } from '~/Item'

import { TextInput, View } from '~/utils/Tags/react-native'
import { getQuestionText, QuestionPrompt } from '~/Item/Prompt'
import { Style } from '../styles'
import { hh } from '~/utils'

// TODO defaultValue doesn't allow for updates that happen outside of UI
interface ITextEntryProps {
	accessibilityLabel?: string
	multiline?: boolean
	value: string | undefined
	submit(text: string): void
}

export const TextEntry = hh(
	({ accessibilityLabel, submit, multiline = false, value }: ITextEntryProps) => {
		return View(Style.TextEntry, [
			TextInput(Style.TextInput, {
				accessible: true,
				accessibilityLabel,
				label: accessibilityLabel,
				defaultValue: value ?? '',
				onChangeText: submit,
				multiline,
				textAlignVertical: 'top',
			}),
		])
	},
	'TextEntry'
)

interface TextConfig {
	inline?: boolean
	multiline?: boolean
	resizeable?: boolean
	pattern?: string
	// titleTemplate?: string
	// maxsize?
	// maxLength?
}
export const TextFieldField =
	(props: ItemFieldRenderer) => {
		const { item, settings } = props

		const submit = (val: string) => {
			const item = props.item
			item.setValue(val)
		}

		const value = item.response?.value ?? ''
		const { multiline } = item?.config ?? {}
		
		return TextEntry({
			value,
			accessibilityLabel: getQuestionText(item).text,
			submit,
			multiline,
		})
	}

export const TextFieldLayout =
	(props: ItemLayoutRenderer) => {
		return null
	}

export const TextItem = ItemType.create({ name: QCode.TextEntry, field: TextFieldField }) // TODO: FieldField ??
