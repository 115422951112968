import { observer } from 'mobx-react'
import { hh, arrayOfLength } from '~/utils'
import { IItemProps, TaxonomyInstance } from '~/interfaces'
import { Text, TouchableOpacity, View } from '~/utils/Tags/react-native'
import { TreePath } from '~/interfaces'
import {
	areSiblings, childrenAreLeafNodes, getOrdinal, isExactSelection, isInSelection, itemIsPageType,
} from '../utils'
import { Progress, ProgressIndicator } from './ProgressIndicator'
import { S } from './styles'
import { OutlineGroup } from './OutlineGroup'
import { useNavigationContext } from '../useTaxonomyNavigation'
// should show branch indicator when:
// item.children.length > 1
// or item is a question and item.children.length > 0
interface IopenBranchIndicator {
	item: TaxonomyInstance
	path: TreePath
	selectedPath: TreePath
}
function openBranchIndicator({ item, path, selectedPath }: IopenBranchIndicator) {
	const shouldShow = !itemIsPageType(item) && item.items &&
		(item.items.length > 1 || (item.Type && item.items.length > 0))
	const indicator = isInSelection(path, selectedPath) ? '-' : '+'
	return Text(S.BranchIndicator, [shouldShow ? indicator : ' '])
}

// TODO: pass isSelected so that it doesn't have to get recalculated so often?
// TODO: improve collapse indicator styling: center icon, matching colors, more subtle border
const whiteBackgroundStyle = { style: { backgroundColor: 'white' } }
function shouldHighlightLeafNodes(path: TreePath, selectedPath: TreePath, parent: TaxonomyInstance) {
	return (areSiblings(path, selectedPath) && childrenAreLeafNodes(parent))
}

function shouldHighlight(path: TreePath, selectedPath: TreePath, parent: TaxonomyInstance) {
	return (
		isExactSelection(path, selectedPath) || shouldHighlightLeafNodes(path, selectedPath, parent)
	)
}

function Score(total: number|string, possible: number|string) {
	return Text(S.Score, [`${total}/${possible}`])
}
interface ICreateOutline {
	item: TaxonomyInstance
	selectedPath: TreePath
	select(path: TreePath): void
}

function SelectedNodeIndent(path: TreePath, displayIndentGuides: boolean) {
	const depth = path.length
	return arrayOfLength(depth - 1).map(
		(_, i) => View({ key: path.join('-') + ':' + i }, [
			View(S.SelectedNodeIndent,
				displayIndentGuides
					? whiteBackgroundStyle
					: {}
			),
		]),
	)
}

const RenderScore = (item: TaxonomyInstance) => {
	const { total, possible } = item.getScore()
	const showScore = item.progress?.() === Progress.Completed && possible > 0
	// console.log({ showScore, total, possible})
	return showScore
		? possible > 0 && Score(total, possible)
		: item.progress() === Progress.InProgress && Score('-', '-')
}

export const OutlineItem = hh(observer(
	({ item }: IItemProps) => {
		const { select, selectedPath } = useNavigationContext()
		const itemPath = item.getPath()
		const highlight = shouldHighlight(itemPath, selectedPath, item.parent)
		const key = '' + item.id + item.selfIndex
		const highlightStyle = highlight
			? whiteBackgroundStyle
			: {}

		// const siblingsAreLeaves = item.parent && item.parent.childrenAreLeaves()
		// const childrenAreLeaves = item.childrenAreLeaves()

		const { id, items, selfIndex, showInOutline, showChildrenInOutline } = item
		// TODO: fix this in ttss
		const showChildren = item.Type === 'Parent'
			? !item.shouldOmit // && !item.childrenAreLeaves()
			: showChildrenInOutline
		// console.log(item.outlineText, {showChildren, showInOutline, shouldOmit: item.shouldOmit})
		const shouldExpand = showInOutline
			&& showChildren
			&& isInSelection(item.getPath(), selectedPath)

		return showInOutline && View(S.TreeNodeHeading, { key }, [
			TouchableOpacity(S.OutlineItem, {
				key, onPress: () => select(item, true),
			}, [
				// item.items &&
					SelectedNodeIndent(itemPath, highlight),
				View(S.TreeNode, highlightStyle, [
					View(S.OutlineItem, /* { title: stringify(item) }, */[
						Text(S.OutlineItemHeading, [
							getOrdinal(itemPath.length, item.selfIndex),
							'. ',
							item.outlineText,
						]),
						RenderScore(item),
						openBranchIndicator({ item, path: itemPath, selectedPath }),
					]),
					ProgressIndicator(item.progress()),
				]),
			]),
			shouldExpand && OutlineGroup(item.items)
		])
	}),
	'OutlineItem'
)
// getOrdinal(itemPath.length, item.selfIndex) '. ',
// depth, Ordinal, text, score, indicator,    progress

// Unused
function createOutline(props: ICreateOutline) {
	const { item, selectedPath } = props
	const { id, items, selfIndex, showInOutline, showChildrenInOutline } = item
	const key = 'outline-' + id + '-' + selfIndex
	// const show = item.isparent && item.childrenAreLeaves()
	// if not show self
	// children are leaves
	const shouldExpand = showInOutline
		&& showChildrenInOutline
		&& isInSelection(item.getPath(), selectedPath)

	return View(S.TreeNodeHeading, { key }, [
		OutlineItem(props),
		shouldExpand && OutlineGroup(item.items)
	])
}
