import { ItemType, ItemTypeFactoryConfig } from '~/Item'
import { IItemProps } from '~/interfaces'
import { Styling } from '~/utils'
import { Text, View } from '~/utils/Tags/react-native'

export interface IQuestionRenderers { [code: string]: ItemType }

export class ItemRegistry {
	public Fallback: ItemType

	constructor(Fallback: ItemTypeFactoryConfig) {
		this.Fallback = ItemType.create(Fallback)
	}

	/**
	 * Contains all currently registered item types
	 */
	private renderers: IQuestionRenderers = {}

	/**
	 * Register a new item type renderer.
	 */
	public register(itemType: ItemType) {
		this.renderers[itemType.name] = itemType
	}

	/**
	 * Return the ItemType that matches `typeName`
	 */
	public lookup = (typeName: string): ItemType => {
		return this.renderers[typeName] ?? this.Fallback
	}

	/**
	 * Return a list of the currently registered item types.
	 *
	 * [[ 'name', ItemType]]
	 */
	public list = () => Object.entries(this.renderers)

}

