import { AnswerType } from 'ttss/Taxonomy/Answer'
import { AssessmentInstance } from 'ttss'
import { TaxonomyInstance } from '~/interfaces'

export interface ICollectionRendererOptions {
	item: TaxonomyInstance
}

export interface IResponseValueMapType {
	[heading: string]: AnswerType
}

/**
 * Return an object containing the properties of this entry and all descendants.
 *
 * This data represents the user's responses to the subtaxonomy in the subassessment,
 * and thus the collection entry.
 */
export function getValues(item: TaxonomyInstance, assessment: AssessmentInstance): IResponseValueMapType {
  // Recursively get descendant properties
	const descendantValues = item?.items
		? item.items.map((child: TaxonomyInstance) => getValues(child, assessment))
		: []

  if (!item.heading) return Object.assign({}, ...descendantValues) // TODO: fromEntries?
  
	return {
    ...Object.assign({}, ...descendantValues),
		[item.heading]: item.response?.value ?? '(Empty)'
	}
}

export function getFormValues(item: TaxonomyInstance) {
	const values = getValues(item, item.assessment)
	// getValues recursively makes an object with values for form elements
	// stored as entries as [item.heading]: value
	// Since we save items to the root, we don't want to keep recursively saving that
	if (item.heading) { delete values[item.heading] }
	return values
}

// // Return an object of the values of item and all child items
// export function getValues(item, assessment) {
// 	const children = (item && item.children)
// 		? item.children.map(child => getValues(child, assessment))
// 		: []

// 	return {
// 		...children,
// 		[item.heading]: item.response,
// 	}
// }

// export function getFormValues(item, assessment) {
// 	const values = getValues(item, assessment)
// 	// getValues recursively makes an object with values for form elements
// 	// stored as entries as [item.heading]: value
// 	// Since we save items to the root, we don't to keep recursively saving that
// 	delete values[item.heading]
// 	return values
// }
