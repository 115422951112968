import { IItemProps, TaxonomyInstance } from '~/interfaces'
import { Text, View } from '~/utils/Tags/react-native'
import { hh } from '~/utils/Tags'
import { Styling } from '~/utils'
import { includes } from 'ramda'
import type { PropsWithChildren } from 'react'

const BOLD = { fontWeight: 'bold' }
const PUNCTUATION = ['?', '.', ':', '!', ';']
const hasPunctuation = includes(PUNCTUATION)
const getItemLabel = (item: TaxonomyInstance) => item.prompt ?? item.heading ?? ''

/**
 * Returns the title and description text that should be displayed with the item.
 */
export const getQuestionText = (item: TaxonomyInstance) => {
	const { description } = item
	const label = getItemLabel(item)

	// Add a : after the title if it doesn't already end with some punctuation
	// TODO: add question guidelines to make this unnecessary
	const text = item.description && !hasPunctuation(label[label.length - 1])
		? label + ':'
		: label

	return { text, description, item }
}

interface ItemDescriptionProps { description: string }

/**
 * Render the item description text.
 */
export const ItemDescription = hh(
	({ description, ...rest }: ItemDescriptionProps) => Text(S.Description, rest, [description]),
	'ItemDescription',
)

/**
 * Render the item title text.
 */
export const ItemTitle = hh(
	({ style = BOLD, children, ...rest }) => Text(S.Title, { style, ...rest }, [children]),
	'ItemHeading',
)

export interface IQuestionPromptProps extends IItemProps {
	text: string
	description?: string
	style?: any
}

// interface ItemLabelProps<T extends TaxonomyInstance, S extends {} = {}> {
// 	item: T
// 	style?: S
// }

// const getItemDescription = (item: TaxonomyInstance) => item.description
/**
 * Render the prompt for a question, including the ```prompt`/`heading`, and `description`.
 */
export const QuestionPrompt = hh(
	({ text, item, description, children }: PropsWithChildren<IQuestionPromptProps>) =>
		View(S.Heading, [
			ItemTitle([ text ]),
			description && ItemDescription({ description }),
		]),
	'ItemLabel',
)

export const S = Styling({
	Heading: {
		maxWidth: '70ex',
		textAlign: 'left',
		flexShrink: 1,
		// flex: 0,
		// display: 'block'
	},
	Title: {
		marginRight: '1em',
		maxWidth: '85ex',
	},
	Description: {},
})
