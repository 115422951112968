import { useCallback } from 'react'
import { Pressable, Text } from '~/utils/Tags/react-native'
import { PressableProps } from 'react-native'
import { hh } from '~/utils'

export const NavButton = hh(
	({ title, onPress, children, style = {}, ...rest }: PressableProps) =>
	Pressable({
		onPress,
		style: useCallback(({ pressed }) => ({
			opacity: pressed ? 0.5 : 1,
			backgroundColor: '#CF6F59',
			borderRadius: 40,
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			margin: 5,
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			...style
		}), [style])
	}, [children]),
	'NavButton',
)

export const NavButtonText = (text: string) => Text({ style: { fontSize: 18, color: 'white' } }, [text])
