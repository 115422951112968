import { QCode } from 'ttss/Taxonomy/utils'
import { useAttachmentSrc } from '~/components/AttachmentView'
import { View, Image } from '~/utils/Tags'
import { IconButton } from '~/components/Button'
import { FileUploadCallback, useFileUpload, photoAttachmentFromFile } from '~/utils/useFileUpload'

import { ItemType } from '~/Item'
import { S } from './PortraitPicker.styles'
import { source, svg } from '~/utils/assets'

const PROFILE_IMG_PLACEHOLDER = 'basics.svg'

// import { useContext } from 'react'
// import { AssessmentContext } from '~/Assessments/AssessmentContext'

export const ProfilePickerItem = ItemType.create({
	name: QCode.Photo,
	field(props) {
		const { item } = props
		const attachment = item.response?.value ?? {}
		const onUpload = (files: File[]) => {
			if (!item.response) item.response = {}
			item.response.setValue(photoAttachmentFromFile(files[0]))
			// save(item, { autoAdvance: false })
		}
		const src = useAttachmentSrc(attachment)

		return View(S.ProfilePicker, [
			View(S.ProfileImageContainer, [
				Image(S.ProfileImage, src ? source(src) : svg('assessments/Basics.svg')),
			]),
			View(S.PickerButtons, [
				TakePhotoButton({ onUpload, style: { marginBottom: '5px' } }),
				ChoosePhotoButton({ onUpload }),
			]),
		])
	}
})

interface TakePhotoButtonProps {
	style: any
	onUpload: FileUploadCallback
}
export const TakePhotoButton = ({ onUpload, style = {} }: TakePhotoButtonProps) => {
	const [_files, triggerUploadPrompt] = useFileUpload(onUpload)
	return IconButton({ text: 'Take Photo', icon: 'camera.svg', onPress: triggerUploadPrompt, style })
}
// const TakePicture = () => {
//	navigator.mediaDevices.getUserMedia()
//		.then((stream) => { })
// }

export const ChoosePhotoButton = ({ onUpload, style = {} }: { onUpload: FileUploadCallback }) => {
	const [_files, triggerUploadPrompt] = useFileUpload(onUpload)
	return IconButton({ text: 'Choose Photo', icon: 'upload_photo.svg', onPress: triggerUploadPrompt, style })
}
