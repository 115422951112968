import { useContext, useCallback } from 'react'

import { QCode } from 'ttss/Taxonomy/utils'
import { DateItem } from 'ttss/Taxonomy/DateItem'

import { ItemType } from '~/Item'
import { DateInput } from './DateInput'
import { View } from '~/utils/Tags/react-native'
import { IItemProps } from '~/interfaces'
import { Style as S} from '../styles'
import { IDate } from './utils'
import { AssessmentContext } from '~/Assessments/AssessmentContext'

const dateStyle = { flex: 1, padding: 2 }

export const DateField =
	(props: IItemProps<DateItem>) => {
		const { save } = useContext(AssessmentContext)
		const { item } = props

		const setDate = useCallback(
			(newDate: IDate) => {
				item.setValue(newDate)
				save(item)
			}, [save, item])

		return View(S.DateItem, [
			DateInput({
				selectedDate: item.response?.value,
				onChange: setDate,
				validRange: [{ year: 1900 }],
				style: dateStyle,
			}),
		])
	}

export const DateRenderItem = ItemType.create({
	name: QCode.YMDDate,
	field: DateField,
})
