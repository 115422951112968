import { useRef, useLayoutEffect, useCallback, useDebugValue } from 'react'

export function useHandler<F extends Function>(handler: F) {
	// const handlerRef = useRef(null);
	const handlerRef = useRef(handler);
	useDebugValue(handlerRef.current)
	// In a real implementation, this would run before layout effects
	useLayoutEffect(() => {
		handlerRef.current = handler;
	});

	return useCallback((...args) => {
		// In a real implementation, this would throw if called during render
		const fn = handlerRef.current;
		return fn(...args);
	}, []);
}
