import { VoidFn } from '~/interfaces'
import { Button, View } from '~/utils/Tags/react-native'
import { S } from './styles'

// backgroundColor: '#DFAF99',
// TODO: make a touchable?
const color = '#CF6F59'
const defaultSubmitButton = ({ save }: { save: VoidFn }) =>
	View(S.ButtonWrapper, [ Button({ title: 'Save', color, onPress: save }) ])

// const saveButton = item.heading === 'Attachment Form'
// 	?	UploadAttachmentButton({ text: `Add New`, onUpload: this.save })
// 	: defaultSubmitButton

const saveButton = defaultSubmitButton

const closeButton = ({ close }: { close: VoidFn }) =>
	View(S.ButtonWrapper, [ Button({ title: 'Close', color, onPress: close }) ])

const cancelButton = ({ cancel }: { cancel: VoidFn }) =>
	View(S.ButtonWrapper, [ Button({ title: 'Cancel', color, onPress: cancel }) ])

const editButton = ({ edit }: { edit: VoidFn }) =>
	View(S.ButtonWrapper, [ Button({ title: 'Edit', color, onPress: edit }) ])

const removeButton = ({ remove }: { remove: VoidFn }) =>
	View(S.ButtonWrapper, [ Button({ title: 'Remove', color, onPress: remove }) ])

export interface IFooterProps {
	close?(): void
	edit?(): void
	cancel?(): void
	save?(): void
	remove?(): void
}
export const Footer = ({ close, cancel, edit, save, remove }: IFooterProps) =>
	View({ style: { flexDirection: 'row'} }, [
		// TODO: pass children instead
		save && saveButton({ save }),
		cancel && cancelButton({ cancel }),
		close && closeButton({ close }),
		edit && editButton({ edit }),
		remove && removeButton({ remove }),
	])
