import { NotesPane } from './NotesPane'
import { svg } from '~/utils/assets'
import { useToggler } from '~/utils'
import { hh } from '~/utils/Tags'
import { Pressable, Image, Text, View } from '~/utils/Tags/react-native'
import { useNavigationContext } from '../useTaxonomyNavigation'

import { Menu } from './Menu'
import { S } from './styles'
import { NavButton, NavButtonText } from './NavButton'

// interface INavigationButtons {}
export const Footer = hh((props) => {
	const [ shouldShowMenu, toggleShowMenu ] = useToggler(false)

	return View(S.Footer, [
		View(S.FooterButtons, [
			NotesPane(),
			View(S.MainControls, [
				shouldShowMenu && Menu({ toggleShowMenu }),
				Pressable({ onPress: toggleShowMenu, style: { aspectRatio: 1, height: '100%' } }, [
					Image(svg('settings.svg', { style: { height: '100%', width: '100%' } }))
				]),
				NavigationButtons(),
			]),
		]),
	])
}, 'Footer')

const NavigationButtons = hh(() => {
	const { next, prev, nextUnscored } = useNavigationContext()

	return View(S.NavigationButtons, [
		NavButton({ onPress: prev }, [ // TODO emit(PREV)/dispatch(PREV)/send(PREV)
			View(S.IconWrapper, [Image(S.Icon, svg('assessments/next.svg', { style: { transform: 'scale(-1,1)' } }))]),
			NavButtonText('Previous')
		]),
		NavButton({ onPress: nextUnscored, style: { flex: 2 } }, [
			NavButtonText('Next Unscored'),
			View(S.IconWrapper, [Image(S.Icon, svg('assessments/nextUnscored.svg'))]),
		]),
		NavButton({ onPress: next }, [
			NavButtonText('Next'),
			View(S.IconWrapper, [Image(S.Icon, svg('assessments/next.svg'))])
		])
	])
})
