import preval from 'preval.macro'
import Bowser from 'bowser'
import { BUILD_TIME as TTSS_BUILD_TIME } from 'ttss'

const minutesSinceMidnight = (date: Date) => date.getHours() * 60 + date.getMinutes()
const timestampAsBuildVersion = (timestamp: number) => {
	const date = new Date(timestamp)
	return `${date.getFullYear()}.${date.getMonth()}.${date.getDay()}-${minutesSinceMidnight(date)}`
}

export const BUILD_TIME = preval`module.exports = Date.now()`

export const diagnostics = {
	// TODO: ensure this works with react-native
	time: new Date(),
	client: Bowser.parse(window.navigator.userAgent),
	versions: {
		ui: timestampAsBuildVersion(BUILD_TIME),
		ttss: timestampAsBuildVersion(TTSS_BUILD_TIME),
		// taxonomy version
		// assessment version
	},
	host: {
		href: window.location.href,
		// ip
	},
	remote: {
		 // ip:
	},
	// user: {} // username
	state: {
		// loaded assessment
		// loaded taxonomy
		// selected item
		// event history
		// 
	},
	// storage status
	// cookie status
	// app state
}

export const getDiagnostics = (): Diagnostics => ({
	...diagnostics,
	time: new Date(),
})
export const diagnosticsAsString = (diagnostics: Diagnostics) => {
	const { platform, browser, engine, os } = diagnostics.client
	return `# Version Info
UI Build Time: ${diagnostics.versions.ui}
TTSS Build Time: ${diagnostics.versions.ttss}

# Client Info
Platform: ${platform.type} ${platform.vendor ?? ''} ${platform.model ?? ''}
OS: ${os.name} ${os.versionName ?? ''} ${os.version ?? ''}
Browser: ${browser.name} ${browser.version ?? ''}
Engine: ${engine.name} ${engine.version ?? ''}

# Host
${diagnostics.host.href}

# Application State
-
`
}

export type Diagnostics = typeof diagnostics
