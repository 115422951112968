import { TaxonomyInstance } from '~/interfaces'
import { hh } from '~/utils'
import { Text, TouchableOpacity, View, Image, Pressable } from '~/utils/Tags/react-native'
// import { GroupItem } from '../Group'
import { Footer } from './Footer'
import { collectionTypes } from './'
import { getFormValues } from './utils'
import { S } from './styles'

import { Group } from '../Group'

// type CollectionItem = TaxonomyInstance<ICollectionItem>
export interface IListItemProps {
	key: string | number
	idx: number
	assessment: any
	selectedPathItems: any[]
	item: TaxonomyInstance
	selected: number
	select(index: number): void
	save(value?: any): void
	remove(): void
}

import { observer } from 'mobx-react'
import { svg } from '~/utils/assets'
import { IconButton } from '~/components/Button'
export const ListItem = hh(observer(
	(props: IListItemProps) => {
		const { item, selected, select, idx, remove, save } = props

		const close = () => {
			select(-1)
			save()
		}

		if (item.config?.editInline && (selected === idx)) { // Editing?
			return View(S.OpenListEntry, [
				Text(S.OpenListEntryHeader, [item.outlineText]),
				Group(props),
				Footer({ close, remove }),
			])
		}


// [
//			!!image && Image(S.ItemIcon, fileSrc(image.src)),
//			Text([ Name ]),
//			Spacer,
//       Species && Text([ Species ]),
//			// Species && Text([ Species[0].name ]),
//		]

		// return TouchableOpacity(S.ListEntry, {
		//	onPress: () => select(idx),
		// }, [collectionItem])

		return TouchableOpacity(S.ListEntry, {
			onPress: () => select(idx),
		}, [
			Image(S.ItemIcon, /* need solution here*/),
			Text({ style: { fontWeight: 'bold', flexGrow: 1 }}, [ item.outlineText ]),
			Pressable({ onPress: () => remove(idx), style: { justifySelf: 'flex-end', aspectRatio: 1, height: '4ex' } }, [
				Image(svg('x.svg', { style: { height: '100%', width: '100%' } }))
			]),
		])
	}),
	'ListItem',
)
