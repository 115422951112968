import { Styling } from '~/utils'

export const S = Styling({
	ListEntry: {
		backgroundColor: '#DCAB9B',
		flexDirection: 'row',
		margin: 10,
		borderRadius: '50px 20px 20px 50px',
		alignItems: 'center',
		// paddingRight: '2em',
		padding: 5,
		alignSelf: 'stretch',
	},
	OpenListEntry: {
		borderRadius: '1em',
		backgroundColor: '#DCAB9B',
		padding: '1em',
		margin: 10,
		// flex: 1,
		float: 'left',
		display: 'inline-block',
		flexShrink: 1,
		flexDirection: 'column',
	},
	OpenListEntryHeader: {
		fontSize: 16,
		fontWeight: 'bold',
		marginBottom: 5,
	},
	ItemIcon: { width: 40, height: 40, resizeMode: 'stretch' },
	ThumbnailButton: {
		width: 80, height: 80, marginTop: 10, marginRight: 10,
		borderStyle: 'solid', borderWidth: 1, borderColor: '#55444A',
	},
	ImageNotesInput: { backgroundColor: '#E8E8E8', padding: 4, marginTop: 10, marginBottom: 10 },
	ImagePreview: { width: 400, height: 400 },
	horizontalRule: { height: 2, backgroundColor: 'black' },
	ThumbnailImage: { width: 80, height: 80 },
	Items: {
		flexDirection: 'column',
		flexShrink: 1,
		alignItems: 'flex-start',
		alignSelf: 'stretch',
		flexGrow: 1,
	},
	ListItem: {
		flexShrink: 1,
		// flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	ButtonWrapper: {
		// width: 400,
		borderRadius: '2em',
		backgroundColor: '#CF6F59',
		padding: 5,
		overflow: 'hidden',
		margin: 10,
	},
	ListItemText: {
		color: 'black',
		fontSize: '1.1em',
		marginLeft: '1em',
		marginRight: '1em',
	},
	CollectionOptions: {
		marginTop: '1.5em',
		flexDirection: 'row',
		flexShrink: 1,
		flexGrow: 1,
		flexWrap: 'wrap',
	},
	// CollectionOption: {
	//	flexBasis: 1
	// }
})
