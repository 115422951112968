// import { inspect } from '@xstate/inspect';
//			inspect({
//				iframe: false,
//				//url: 'https://stately.ai/viz?inspect',
//			});
const log = (...args) => console.log('Notes: ', ...args)

const animateP = (translateY, opacity, abortController) =>
	(toY, toOpacity) => {
		translateY.flattenOffset()
		opacity.flattenOffset()
		return new Promise((resolve, reject) => {
			const animation = Animated.parallel([
				Animated.spring(translateY, {
					toValue: toY,
					velocity: 1,
					useNativeDriver: false,
				}),
				Animated.timing(opacity, {
					toValue: toOpacity,
					duration: 200,
					useNativeDriver: false,
				})
			], { stopTogether: true })

			animation
				.start(({ finished }) => {
					if (!finished) return reject()
					translateY.extractOffset(toY)
					opacity.extractOffset(toOpacity)
					resolve(null)
				})
			abortController?.signal?.addEventListener('abort', () => {
				animation.stop()
				reject()
			})
		})
	}
const fromAbortable = (create) =>
	createMachine({
		context: () => ({
			abortController: new AbortController(),
		}),
		exit: ({ abortController }) => {
			abortController.abort()
		},
		entry: assign({
			abortController: new AbortController(),
		}),
		initial: "loading",
		states: {
			loading: {
				invoke: {
					src: (context) => create(context),
					onDone: 'done',
					onError: { actions: escalate((_, { data }) => data) }
				},
			},
			done: {
				type: 'final',
				data: (_, { data }) => data
			}
		},
	});


import { svg } from '~/utils/assets'
import { hh, useHandler } from '~/utils'
import { Image, Text, PanResponder, View, TouchableOpacity, AnimatedView } from '~/utils/Tags/react-native'
import { Notes } from './Notes'
import { S } from './styles'
import { Animated, GestureResponderEvent, PanResponderGestureState } from 'react-native'
import { assign, createMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import { escalate } from 'xstate/lib/actions';

const OPEN_OFFSET = -220
const PRESS_TIME = 200
const SWIPE_THRESHOLD = -30
const OPACITY = 0.6

const machine = createMachine({
	id: 'GlobalNotesDrawer',
	initial: 'closed',
	context: {
		translateY: new Animated.Value(0),
		opacity: new Animated.Value(0),
	},
	states: {
		open: { on: { TOGGLE: 'animating-toClosed' } },
		'animating-toClosed': {
			invoke: {
				src: fromAbortable(({ translateY, opacity, abortController}) =>
					animateP(translateY, opacity, abortController)(0, 0)),
				data: ({ translateY, opacity }) => ({ translateY, opacity, }),
				onDone: 'closed',
			},
			on: { TOGGLE: 'animating-toOpen' },
		},
		'animating-toOpen': {
			invoke: {
				src: fromAbortable(({ translateY, opacity, abortController}) =>
					animateP(translateY, opacity, abortController)(OPEN_OFFSET, OPACITY)),
				data: ({ translateY, opacity }) => ({ translateY, opacity, }),
				onDone: 'open',
			},
			on: { TOGGLE: 'animating-toClosed' }
		},
		closed: { on: { TOGGLE: 'animating-toOpen' }, },
	}
})

export const NotesPane = hh(
	() => {
		const [ state, send ] = useMachine(machine, { devTools: true })
		const { translateY, opacity } = state.context
		const icon = !state.matches('closed')
			? svg('assessments/closeGlobalNotes.svg')
			: svg('assessments/openGlobalNotes.svg')

		const show = !state.matches('closed')
		// TODO: animate the box-shadow. Currently it pops away after the animation is done.
		const boxShadow = show && '7px 2px 15px -1px rgba(0,0,0,0.66)'
		const toggle = useHandler(() => send('TOGGLE'))
		// useEffect(() => {
		//	translateY.addListener(v => log('y:', v))
		// }, [translateY])
		global.TOGGLE = () => {
			send('TOGGLE')
			setTimeout(() => send('TOGGLE'), 100)
		}
		return View({	style: {zIndex: 100} }, [
			show && AnimatedNotesBackdrop({ opacity }),
			AnimatedView(S.Notes, {
				style: { boxShadow, transform: [{ translateY }] }
			}, [
				show && Notes(),
				TouchableOpacity(S.Handle, { onPress: toggle }, [ // {...panHandlers},
					Image(S.HandleIcon, icon),
					View(S.HandleLabel, [
						Text(S.HandleLabelText, ['General']),
						Text(S.HandleLabelText, ['Notes & Drawings']),
					]),
				]),
			])
		])
	},
	'Footer',
)

const AnimatedNotesBackdrop = hh(
	({ opacity, children }) => AnimatedView(S.NotesBackdrop, { style: { opacity }}, children),
	'AnimatedNotesBackdrop',
)

// const onPanResponderGrant = useCallback((_e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
//	dragTimeRef.current = Date.now()
//	setPressing(true)
// }, [isOpen, pressing, dragging])

// const event = useRef(Animated.event([
//	null,
//	{ dy: translateY }
// ], {
//	listener: (e, g) => {
//		if (dragTimeRef.current > PRESS_TIME) setDragging(true)
//	},
//	useNativeDriver: false
// }))

//		const panResponder = useRef(
//			PanResponder.create({
//				// Should element become active when the user presses down on the circle?
//				onStartShouldSetPanResponder: () => true,
//				// Should element become active when the user merely moves a touch over the circle?
//				onMoveShouldSetPanResponder: () => false,
//				onPanResponderGrant,
//				onPanResponderMove: event.current,
//				onPanResponderRelease: onPanResponderEnd,
//				onPanResponderTerminate: onPanResponderEnd,
//			})).current

// const panHandlers = panResponder?.panHandlers ?? {}

//		const onPanResponderEnd = useCallback((_e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
//			const shouldOpen = openRef.current
//			if ((Date.now() - dragTimeRef.current) < PRESS_TIME) toggleIsOpen()
//			else toggleIsOpen(gestureState.dy < SWIPE_THRESHOLD)

//			setDragging(false)
//			setPressing(false)
//			setTimeout(() => startAnimating(shouldOpen), 0)
//		}, [isOpen])
