import { QCode } from 'ttss/Taxonomy/utils'
import { ItemType } from '~/Item'
import { useContext, useState } from 'react'
import { hh, firstNonEmpty, isTruthy, removeIdx } from '~/utils'
import { Button, Text, View } from '~/utils/Tags/react-native'
import type { CollectionItem } from 'ttss/Taxonomy/CollectionItem'
import { TaxonomyInstance } from '~/interfaces'
import { ResponseInstance } from 'ttss/Taxonomy/Responses'
import { IItemProps } from '~/interfaces'
import { ListItem } from './ListItem'
import { S } from './styles'
import { AssessmentContext } from '~/Assessments/AssessmentContext'
import { IconButton } from '~/components/Button'

interface ICollectionDisplayState {
	selected: number
	item: TaxonomyInstance
}
// TODO: properly type. Subtype Collection?
type CollectionType = any

export const CollectionField =
	(props: IItemProps<CollectionItem>) => {
		const [selected, setSelected] = useState(-1)
		// const { save } = useContext(AssessmentContext)
		const { item } = props

		const update = (idx: number, editedValue: ResponseInstance) => {
			// if (typeof idx !== 'number') { throw new Error('Save requires an index.') }
			// const value = item.responseValue ?? []

			// TODO: only save if required fields are filled
			// const newValue = [ ...value ]
			// newValue[idx] = editedValue
			// item.setValue(newValue)
			// save(item)
		}

		const addItem = (templateIdx: number) => () => {
			item.appendEntry(templateIdx)
			// save(item)
			setSelected(item.items?.length)
		}

		const remove = (index: number) => {
			console.log('removing', index)
			item.removeEntry(index)
			setSelected(-1)
			// save(item)
		}

		const defaultSubmitButton = View(S.ButtonWrapper, [
			// TODO: make touchable?
			Button({
				title: `Add ${item.heading}`,
				color: '#CF6F59',
				onPress: addItem,
			}),
		])

		// const submitButton = item.heading === 'Attachment Form'
		//	?	UploadAttachmentButton({ text: `Add New`, onUpload: this.addItem, doc: rootAssessment })
		//	: defaultSubmitButton

		const getHeading = (responses) => {
			// TODO: address fields in taxonomy
			const humanName = [
				responses['First Name'],
				responses['Last Name'],
			].filter(isTruthy).join(' ')

			return firstNonEmpty([
				humanName,
				responses.caption,
				responses.name,
				'(Empty)',
			])
		}

		const items = item?.items.map(
			(child: TaxonomyInstance, idx: number) =>
				ListItem({
					...props,
					key: child.assessment._id,
					item: child,
					select: setSelected,
					idx,
					selected,
					getHeading,
					remove: () => remove(idx),
					save: (newValue: CollectionType) => update(idx, newValue),
					itemType: item.heading,
				})) ?? []

		return View(S.Items, [
			items.length > 0
				? items
				: Text([`No ${item.heading} has been added.`]),
			CollectionOptions({ item, addItem }) // defaultSubmitButton,
		])
	}

const CollectionOptions = ({ item, addItem }) => {
	return View(S.CollectionOptions,
		item.taxonomies?.map(
			(taxonomy, idx) =>
				IconButton({ onPress: addItem(idx), icon: taxonomy.icon, text: '+ ' + taxonomy.heading })
		)
	)
}
export const Collection = ItemType.create({
	name: QCode.Collection,
	field: CollectionField,
})
