
import { Styling } from '~/utils'
import { Text, View } from '~/utils/Tags/react-native'

const S = Styling({
	FallbackItem: {
		marginTop: '1em',
		flexDirection: 'column',
		flex: 1,
		justifyItems: 'flex-start',
		minHeight: 'min-content', // Needed for proper vertical spacing in chrome/safari
		backgroundColor: '#2b467c',
		padding: '.5em',
		borderRadius: 8,
		marginBottom: '1em',
	},
	Group: {
		paddingTop: '1em',
		alignSelf: 'stretch'
	},
})
export const Fallback = {
	name: 'Fallback',
	field:
		({ item }) =>
			View(S.Group, [
				View(S.FallbackItem, [
					Text({ style: { color: 'white', fontWeight: 'bold' } }, ['This feature is coming soon!']),
				]),
			]),
}
