import { useContext, useMemo } from 'react'
import { observer } from 'mobx-react'
import { Instance, getType, getSnapshot } from 'mobx-state-tree'

import { Photo } from 'ttss/Taxonomy/DataTypes'
import { PhotoView } from './Photo'
import { PhotoAttachmentView } from '~/components/AttachmentView'
import { hh, Styling, useHandler } from '~/utils'
import { View } from '~/utils/Tags/react-native'
import { IPhoto } from '~/interfaces'
import { TaxonomyInstance } from '~/interfaces'
import { AssessmentContext } from '~/Assessments/AssessmentContext'
import { useNavigationContext } from '~/Assessments/useTaxonomyNavigation'
import { IconButton } from '~/components/Button'
import { useFileUpload, photoAttachmentFromFile } from '~/utils/useFileUpload'

export interface IPhotosProps {
	photos: IPhoto[]
	onUpload(): any
	//uploadInfo: UploadButtonMakerProps
}
// Unused?
export const PhotosItem = hh(observer(
	({ photos, onUpload }: IPhotosProps) => {
		const [_files, triggerUploadPrompt] = useFileUpload(onUpload)
		return View(S.PhotoField, [
			photos,
			IconButton({
				text: 'Add Photo',
				icon: 'camera.svg',
				onPress: triggerUploadPrompt
			})
		])
	}),
	'PhotosItem',
)
// Used in default layout
export const PhotoField = hh(observer(
	({ item }) => {
		const { assessment, save } = useContext(AssessmentContext)
		const onUpload = (files: File[]) => {
			if (!item.response) item.response = {}
			files.forEach(file => item.response.addPhoto(photoAttachmentFromFile(file)))
			// save(item, { autoAdvance: false })
		}
		const photos = item.response?.photos ?? []
		const [_files, triggerUploadPrompt] = useFileUpload(onUpload)

		return View(S.PhotoField, [
			...photos.map(
				(photo: Instance<typeof Photo>) =>
					PhotoAttachmentView({
						key: photo.id,
						attachment: photo,
						component: PhotoView,
						extra: { style: { margin: '5px', marginLeft: 0 } },
					}),
			),

			IconButton({
				text: 'Add Photo',
				icon: 'camera.svg',
				onPress: useHandler(triggerUploadPrompt),
			})
		])
	}),
	'PhotoField',
)

const S = Styling({
	PhotoField: {
		flexDirection: 'row',
		textAlign: 'center',
		paddingTop: '0.5ex',
		overflow: 'hidden',
		flexWrap: 'wrap',

		// border: '1px solid gray',
		padding: '1ex',
		// borderRadius: '0.4ex',
		marginTop: '.5ex',
	},
	// Thumbnails: {
	// 	display: 'flex',
	// 	flexGrow: 0,
	// 	alignItems: 'center',
	// 	flexDirection: 'row',
	// }
})
