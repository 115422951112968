import { useContext } from 'react'

import { QCode } from 'ttss/Taxonomy/utils'
import type { AttachmentItem } from 'ttss/Taxonomy/AttachmentItem'

import { View } from "~/utils/Tags/react-native"
import { ItemType } from '~/Item'
import { IItemProps } from '~/interfaces'

import { AssessmentContext } from '~/Assessments/AssessmentContext'

import { AttachmentButton } from "./AttachmentButton"
import { useFileUpload, photoAttachmentFromFile } from '~/utils/useFileUpload'

export const Attachment = ItemType.create({
	name: QCode.Attachment,
	field: (props: IItemProps<AttachmentItem>) => {
		const { save } = useContext(AssessmentContext)
		const { item } = props
		const onUpload = (files: File[]): void => {
			// TODO: abort if missing caption or image
			const newAttachment = files[0]
			props.item.setValue(photoAttachmentFromFile(newAttachment))
			// save(item)
		}
		const [_files, triggerUploadPrompt] = useFileUpload(onUpload)

		return View([
			// value.name
			// value.caption
			// value.filename // from id
			AttachmentButton({
				text: item.heading ?? '',
				backgroundColor: '#E8E8E8',
				isButton: false,
				onPress: triggerUploadPrompt,
				value: item.response?.value,
				required: false,
			}),
		])
	},
})
