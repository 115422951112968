import { find, complement, isEmpty } from 'ramda'
export const firstNonEmpty = find(complement(isEmpty))
export const isTruthy = (x: any) => !!x
export const objIf = <T>(condition: boolean, obj: T) =>
	condition
		? obj
		: {}

	// addMiddleware(assessment, (call, next) => {
	//         const { context, type, name, args } = call
	//         const { id, heading } = context
	//         if (!["afterAttach"].includes(name))
	//                 console.log(`(${id})${heading}:${type} ${name}`, ...args)
	//         next(call)
	// }, true)

// export type HComponent<Props extends {} = {}> = ComponentType<Props> & ((props: Props) => ReactElement<any, any>)
export { hh, h, Styling } from './Tags/hh'
// export * from './Tags/react-native'
// export * from './Tags/svg'
import { AssessmentZip } from 'ttss/Repository/Assessment'

type IndexType = number | string
export function get(obj: object, path: IndexType[], fallback?: any) {
	let nextObj = obj
	for (const node of path) {
		if (!nextObj) { return fallback }
		nextObj = (nextObj as any)[node]
	}
	return nextObj
}

// export function set(obj: object, path: IndexType[], value: any) {
//	let nextObj = obj
//	// navigate to just before the last path in case we need to trigger any setters
//	for (let i = 0; i < path.length - 1; i++) {
//		if (!nextObj) { throw new Error('Invalid object path') }
//		nextObj = nextObj[path[i]]
//	}
//	nextObj[path[path.length - 1]] = value
// }

// type ArrayOrObject = {} | any[]

/**
 * Clone the passed object, replacing the path with the new value.
 *
 * TODO: use `key of`
 */
export function pureSet<T extends {}>(obj: T, path: IndexType[], value: any) {
	// copy the object as we descend
	const [current, ...rest] = path
	const newObj: T = Array.isArray(obj)
		? [...obj] as any as T
		: { ...obj } as any as T;
	(newObj as any)[current] = rest.length === 1 ? pureSet(newObj, rest, value) : value
	return newObj
}

export const range = (n: number) => new Array(Math.max(n, 1)).fill(0)

export const removeIdx = (removeIndex: number, array: any[]) =>
	array.filter((_, idx) => idx !== removeIndex)

export { useToggler } from './useToggler'
export { useHandler } from './useHandler'
export { useGetState } from './useGetState'

// TODO: find out if just using fill() would work here
export const arrayOfLength = (n: number) => Array(Math.max((n - 1), 0)).fill(undefined)

export function maybe<O, I = any>(fn: (...i: I[]) => O, fallback: O) {
	return (...i: I[]) => {
		let ret
		try {
			ret = fn(...i)
		} catch (e) {
			ret = fallback
		}
		return ret
	}
}
// export const front = maybe(<T>(array: T[]) => array.slice(0, array.length-1), [])
export const front = <T>(array: T[], fallback: T[]) =>
	maybe((arr: T[]) => arr.slice(0, arr.length - 1), fallback as T[])(array, fallback)

export const first = <T>(array: T[]) => array[0]
export const last = <T>(array: T[]) => array[array.length - 1]

// https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
export function downloadFile(data: string, fileName: string) {
	const dataStr = 'data:application/octet-stream;charset=utf-8,' + encodeURIComponent(data)
	// const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
	const downloadAnchorNode = document.createElement('a')
	downloadAnchorNode.setAttribute('href', dataStr)
	downloadAnchorNode.setAttribute('download', fileName)
	document.body.appendChild(downloadAnchorNode) // required for firefox
	downloadAnchorNode.click()
	downloadAnchorNode.remove()
}
global.downloadFile = downloadFile
import { omit, pipe } from 'ramda'
import { getSnapshot, applyPatch, addMiddleware } from 'mobx-state-tree'
export const applyPartialPatch = (node) => (obj) =>
	Object.entries(obj).forEach(
		([key, value]) =>
			applyPatch(node, { op: 'replace', path: `/${key}`, value })
	)

import { Taxonomy, AssessmentInstance, Assessment as MSTAssessment } from 'ttss'
import hestia from '~/newTaxonomy.json'
import { SnapshotIn } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { clearInterval } from 'timers';
import * as TE from 'fp-ts/lib/TaskEither'
import { prop, indexBy } from 'ramda'
import { EventSystem } from '~/EventSystem'

export const createLoadAssessment = (eventSystem: EventSystem) =>
	eventSystem.createAction(
		'LoadAssessment',
		async (data?: SnapshotIn<AssessmentInstance>) => {
			// console.log('Loading assessment:', data)
			const initialData = data
				? JSON.parse(data)
				: {}

			const persistence = await AssessmentZip.fromFile()
			global.persistence = persistence
			global.TE = TE

			const assessment = MSTAssessment.create({
				_id: uuid(),
				responses: {},
				attachments: {},
				...initialData,
				// TODO: is this needed now that env.items exists?
				// it might still be used by ttss?
				taxonomy: Taxonomy.create(hestia[0]),
				// applyPartialPatch(assessment)
			}, {
				// TODO: does this work for nested assessments?
				persistence,
				items: indexBy(prop('id'), hestia)
			})

			return precomputeScores(assessment)
		}
	)
export const loadAssessment = async (data?: SnapshotIn<AssessmentInstance>) => {
	// console.log('Loading assessment:', data)
	console.log(`[events] Loading assessment`, data)
	const initialData = data
		? JSON.parse(data)
		: {}

	const persistence = await AssessmentZip.fromFile()
	global.zip = AssessmentZip
	global.persistence = persistence
	global.TE = TE

	const assessment = MSTAssessment.create({
		_id: uuid(),
		responses: {},
		attachments: {},
		...initialData,
		// TODO: is this needed now that env.items exists?
		// it might still be used by ttss?
		taxonomy: Taxonomy.create(hestia[0]),
		// applyPartialPatch(assessment)
	}, {
		// TODO: does this work for nested assessments?
		persistence,
		items: indexBy(prop('id'), hestia)
	})

	return precomputeScores(assessment)
}

const precomputeScores = (assessment: any) => {
	try {
		let i = 0
		const interval = setInterval(() => {
			i++;
			try {
				console.log(`${i}:`, { ...assessment.taxonomy.getScore() })
				eval('clearInterval(' + interval + ')')
				// clearInterval(interval)
			} catch (e) {
				console.log(`${i}: Failed to get score!!`)
				console.log(e.message)
			}
		}, 10)
	} catch (e) { }
	return assessment
}

export const logGroup = (...logData) => fn => (...args) => {
	console.group(...logData)
	const ret = fn(...args)
	console.log('Returning: ', ret)
	console.groupEnd()
	return ret
}
export const copyMSTInstance = (instance) => getType(instance).create(getSnapshot(instance))

export const debug = (message: string) => <T>(value: T) => {
	console.log(message, value)
	return value
}
