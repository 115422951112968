import * as CSS from 'csstype'
import { hh, Styling, TouchableOpacity, Image } from '~/utils/Tags'

const S = Styling({
	IconButton: {
		aspectRatio: '1/1',
//		width: '4ex',
		height: '3ex',
		marginRight: '1ex',
	}
})
export const IconButton = hh(
	({ icon, onPress }) =>
		TouchableOpacity({ onPress }, [
			Image(S.IconButton, icon),
		]),
	'IconButton',
)
