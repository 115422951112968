import * as TE from 'fp-ts/TaskEither'
import { pipe } from 'fp-ts/function'
import autoBind from 'auto-bind'
import * as R from 'ramda'

export type Err = string & { Error: true }
export const Err = (error: string): Err => error as Err
export type FailableTask<Success> = TE.TaskEither<Err, Success>
type Void = undefined
// A type descriminator for a return.
// Represents the type that is ultimately returned by a function,
// whether returned directly, wrapped in a promise, or in a task
type VoidSuccess<R> = R extends void | null
	? void
	: R extends Promise<any>
	? Promise<void>
	: R extends TE.TaskEither<any,any>
	? void
	: never;
type VoidResult = Void | Promise<Void> | FailableTask<Void>

	// VoidSuccess<R>
	// R extends void | null
	// ? void
	// : R extends Promise<any>
	// ? Promise<null>
	// : never;
	// type VoidResult = Void | Promise<Void> | TE.TaskEither<Err,Void>

export function isPromise<T>(value: any | Promise<T>): value is Promise<T> {
	return value &&
		typeof (<any>value).subscribe !== 'function' &&
		typeof (value as any).then === 'function';
}

export function isTaskEither<L, R>(value: any | TE.TaskEither<L, R>): value is TE.TaskEither<L, R> {
	return value && typeof value === 'function'
}

const fromPromise = <T>(p: Promise<T>) =>
	TE.tryCatch<Err,T>(
		() => p,
		reason => Err('Promise create by toTaskEither failed:' + reason)
	)
const toTaskEither = <T, R>(lazyFn: () => () => R) => {
	const result = lazyFn()

	return R.cond([
		[isPromise, fromPromise],
		[isTaskEither, R.identity],
//		isLazyFunction, TE.tryCatch, // first call and return resul?
		[R.T, TE.of],
	])(result)
}

// Hook parameters:
// triggering node
// trigging hook (establish hook chain)
// action parameters

// create global stack of actions with an action factory?
// actions are promises?
// surround in a task?
import { v4 as uuid } from 'uuid'
// const log = (message: string) => (value?: any) => { console.log(`[EventSystem] ${message}`, value); return value}
type DomainEvent = 'event1' | 'event2'

type ESubscriber<P,R,C> = {
	name: string, // describe the event for logging
	action(payload?: P): R | Promise<R>,
	context?: C
}

type EventSubscriber = <P, R, C>(payload?: P, context?: C) => VoidResult
export class EventSystem {
	// Use monocle-ts here?
	private id: string
	private hookSubscribers: Map<string, EventSubscriber[]> = new Map()

	protected constructor(name: string = uuid().slice(0,4)) {
		this.id = name
		autoBind(this)
	}
	static create(name?: string) { return new EventSystem(name) }

	log(message: string) {
		return <T>(value: T) => {
			console.log(`[${this.id} Event] ${message}`, value)
			return value
		}
	}
	getSubscribers(name: string) { return this.hookSubscribers.get(name) ?? [] }

	subscribe(name: string, fn: EventSubscriber) {
		this.log(`Subscribed to ${name}`)(fn)
		this.hookSubscribers
			.set(name, [ ...this.getSubscribers(name), fn ])
		// this.log('subscribers')(this.hookSubscribers)
		return () => this.unsubscribe(name, fn)
	}

	subscribeAll(hooks: Record<string,EventSubscriber>) {
		const subs = Object.entries(hooks).map(([name, fn]) => this.subscribe(name, fn))
		return () => subs.forEach(unSub => unSub())
	}

	protected unsubscribe(name: string, fn: EventSubscriber) {
		this.hookSubscribers.set(name, R.without([fn], this.getSubscribers(name)))
	}

	emit<Context, Payload>(name: string) {
		return (payload: Payload, context?: Context): FailableTask<Void> => {
			const log = (step: string) => this.log(`(${name}) ${step}`)
			log('Emitting')(payload)
			const runSubscriber = (subscriber: EventSubscriber): FailableTask<Void> => {
				const result = subscriber(payload, context)
				if (isPromise(result)) {
					return TE.tryCatch<Err, Void>(
						() => result,
						// () => subscriber(payload, context),
						reason => Err(`Action ${name} failed: ${reason}`),
					)
				}
				if (isTaskEither(result)) return result
				return TE.of<Err, Void>(result)
			}

			return pipe(
				this.getSubscribers(name).map(runSubscriber),
				TE.sequenceArray,
				TE.map(() => undefined)
			)
		}
	}

	makeAction<P,T>(name: string) {
		return (payload?: P) => this._runAction(name, payload)()
	}

	runAction<P, T>(name: string, payload?: P) {
		return this._runAction(name, payload)()
	}

	_runAction<P, T>(name: string, payload?: P) {
		const log = (step: string) => this.log(`${name} action - ${step} -- `)
		log('Init...')(payload)
		return pipe(
			TE.of(payload),
			// Before the main action happens, has an opportunity to prevent the action
			TE.chainFirst((...args) => {
				log(`:before args - `)(args)
				return this.emit(`before${name}`)(...args)
			}),
			// The main action happens
			TE.chain((...args) => {
				log(':main args - ')(args)
				return this.emit(`${name}`)(...args)
			}),
			// After the main action happens, this hook has an opportunity to do work that depends
			// on the main action having completed successfully
			// NOTE: because a hook can cause multiple subscribed effects, there is no way
			//       to know which effect (or reliably, in which order) results should be passed
			//       to the 'after' effect, therefore `after` cannot rely on the result data from
			//       the action, only that all of the effects have succeeded.
			//       If the result is needed, then the action should fire an additional event
			//          with the needed data included.
			TE.chainFirst((...args) => {
				log(':after args - ')(args)
				return this.emit(`after${name}`)(...args)
			}),
		)
	}

	chainEvent<T>(fn: (payload?: any) => VoidResult, nextEvent: string) {
		// const log = (message: string) => this.log(`->${nextEvent}`)
		return (payload: T) => {
			return pipe(
				toTaskEither(() => fn(payload)),
				TE.map(this.log(`->${nextEvent} mapped`)),
				TE.map((result) => this.emit(nextEvent)(result, payload)),
			)
		}
	}

	createAction<P, T>(name: string, action: (payload: P) => Promise<T>) {
		return (payload: P) => {
			// use Reader for `context` ?
			const context = {
				// environment variables?
			}
			const actionTE = TE.tryCatch(
				() => action(payload),
				reason => Err(`3 -Action ${name} failed: ${reason}`))

			return pipe(
				TE.of(payload),
				// Before the main action happens, has an opportunity to prevent the action
				TE.chain(this.emit(`before${name}`)),
				// The main action happens
				TE.chain(this.emit(`${name}`)),
				// TE.map(actionTE),
				// After the main action happens, has an opportunity to do work that depends
				// on the main action having completed successfully
				TE.chainFirst(this.emit(`after${name}`)),
			)
		}
	}
}

// I have a list of functions that I want to call in sequence
// and return a TE result

const eventSystem = EventSystem.create()
type MyAction = {}
const myAction = eventSystem.createAction('myAction', (payload: MyAction) => {
	return new Promise(() => { })
})

import { createContext, useContext } from 'react'
import { hh } from './utils/Tags'
export const EventContext = createContext<EventSystem>(EventSystem.create())
export const EventProvider = hh(EventContext.Provider)
export const useEventContext = () => useContext(EventContext)

type AssessmentActions = {
	NewAssessment: (node: IAnyStateTreeNode) => {},
	LoadAssessment: 'assessment', // =>
	EnterAssessment: 'asessment',
	SaveAssessment: 'assessment',
	SerializeAssessment: 'assessment',
	DeserializeAssessment: 'assessment',
	LeaveAssessment: 'assessment',
}


// const AssessmentActions = {
//	loadAssessment: createLoadAssessment
// }
const Actions = [
	'NewAssessment',
	// -- Assessment
	// LoadAssessment,
	// EnterAssessment,
	// SaveAssessment // node, assessment
	// SerializeAssessment
	// DeserializeAssessment
	// LeaveAssessment

	// -- Navigation
	// createAnswer?
	// ApplyAnswer // node, oldAnswer, newAnswer
	// SaveAnswer  // node, oldAnswer, newAnswer
	// SerializeAnswer/DeserializeAnswer
	// createResponse?
	// ApplyResponse // node, oldAnswer, newAnswer
	// SaveResponse  // node, oldAnswer, newAnswer
	// SerializeResponse/DeserializeResponse
	// EnterQuestion,
	// LeaveQuestion

	// -- Taxonomy
	// LoadTaxonomy
	// SerializeTaxonomy
	// DeserializeTaxonomy
	// SaveTaxonomy
	// AddItem
	// createItem?
	// deleteItem
	// editItem?
	// moveItem?
]
