import { useEffect, useRef, createContext, useContext, useState, useMemo, useCallback, useDebugValue, useLayoutEffect } from 'react'
import { TaxonomyInstance, TreePathItems } from '~/interfaces'
import { TreePath } from '~/interfaces'
import { Taxonomy } from 'ttss'
import { QCode } from 'ttss/Taxonomy/utils'
import { differenceWith, equals } from 'ramda'
import { useWhyDidYouUpdate } from './useWhyDidYouUpdate'
import { useEventListener } from '@react-hookz/web'
import { debug, hh } from '~/utils'
import { useEventContext } from '~/EventSystem'
export interface SetupTaxonomyNavigation {
	taxonomy: TaxonomyInstance
	path: TreePath
	save(): void
}

export interface TaxonomyNavigation {
	path: number[]
	selected: TaxonomyInstance
	selectedPath: TreePath
	selectedPathItems: TreePathItems

	select(item: TaxonomyInstance, noAutoAdvance?: boolean): TaxonomyInstance
	selectByPath(item: TreePath): TaxonomyInstance

	next(): TaxonomyInstance
	nextUnscored(): TaxonomyInstance
	prev(): TaxonomyInstance
}
let last = {}
const shouldAutoAdvance = (item) => {
	// Must Always autoAdvance:
	if (item.shouldOmit) return true // or anscestors
	if (!item.isSelectable) return true

	// Must Never AutoAdvance
	if (item.isInCollection) { return false }

	return !item.isRelevant()

	// 2. if item is in a page then this item must be the last on the page
	// 3. if children are leaves (ie., displayed on the same page)
	if (item.isLastOnPage) { return true }
	// then this item must be the last sibling
	return true
}

const PlaceHolderItem = Taxonomy.create({ id: 'placeholder', Type: QCode.Parent })
const InitialNavigationState = {
	selected: PlaceHolderItem,
	path: [],
	save: () => {},
}
type useSetupTaxonomyNavigationContext = (
	initialState: SetupTaxonomyNavigation,
) => TaxonomyNavigation

const useRefState = <T>(initialValue: T) => {
	const [ value, _setValue ] = useState(initialValue)
	const ref = useRef(initialValue)
	const setValue = useCallback((newValue: T) => {
		ref.current = newValue
		_setValue(newValue)
	}, [])

	const getValue = useCallback(() => ref.current, [])
	return [ value, setValue, getValue ] as const
}

function useSelection(initialPath = [], taxonomy) {
	// const [selectedPath, setSelectedPath] = useState<TreePath>(initialPath)
	const initialSelection = useMemo(() => taxonomy.getByPath(initialPath), [ taxonomy, initialPath ])

	// useEventHook???
	// run hook beforeNavigation
	// TODO: distinguish userNavigation from autoNavigation
	const [ selected, setSelected, getSelected ] = useRefState<TaxonomyInstance>(initialSelection)
	// run hook afterNavigation
	const selectedPath = useMemo(() => selected?.getPath() ?? [0], [ selected ])

	return {
		selectedPath,

		setSelected,
		selected,
		getSelected,
	} as const
}

//useSetupTaxonomyNavigationContext
export function useSetupTaxonomyNavigationContext(initialState: SetupTaxonomyNavigation) {
	const events = useEventContext()
	const { taxonomy, path, save } = initialState ?? InitialNavigationState
	const { selected, getSelected, setSelected, selectedPath } = useSelection(path, taxonomy)
	console.log('Selected', selected)
	useDebugValue('Selected: ' + getSelected())

	const [ preventAutoAdvance, setPreventAutoAdvance ] = useState(false)
	useLayoutEffect(() =>
		events.subscribeAll({
			Navigate: () => {},
			NavigateNext: () => {
				events.runAction('Select', getSelected().next())
			},
			NavigatePrev: () => {
				events.runAction('Select', getSelected().prev())
			},
			Select: (item: TaxonomyInstance) => {
				console.log('Setting selected:', item)
				setSelected(item)
				return item
			},
			SelectByPath: ({ current, next }) => {
				// Paths: number[]
				if (!equals(current, next)) events.runAction('Select', taxonomy.getByPath(next))
			},
			AutoAdvance: (nextSelection) => {
				const shouldSkip = getSelected().shouldSkip()
				const selected = nextSelection || getSelected()
				if (!preventAutoAdvance && shouldSkip) {
					const next = selected.next()
					console.log(`Auto-advancing from ${selected?.id} to ${next?.id}`)
					return events.runAction('Select', next)()
					// select(next)
				} else {
					console.log(`Not auto-advancing ${selected?.id}`, { preventAutoAdvance, shouldSkip })
				}
			},
			afterSelect: (selected) => {
				// TODO: should happen after scoring, not selecting
				events.runAction('AutoAdvance', selected)
			},
			// save
			// autoAdvance
		}), [taxonomy, setSelected, getSelected])

//	useEffect(() => events.subscribe('afterSelect', events.makeAction('AutoAdvance')), [])
	const select = useCallback((item: TaxonomyInstance) => events.runAction('Select', item), [])
	// const select = useCallback((item: TaxonomyInstance, noAutoAdvance: boolean = false) => {
	//	// TODO: fix this better
	//	console.group('Selecting:', item.name, item.id)
	//	setPreventAutoAdvance(item.isSelectable() && noAutoAdvance)
	//	setSelected(item)
	//	save()
	//	console.groupEnd()
	//	return item
	// }, [ save ])

	const selectByPath = useCallback(
		(nextPath) => events.runAction('SelectByPath', { current: selectedPath, next: nextPath })
		,[selectedPath])
	// const selectByPath = useCallback((newPath: number[]) => {
	//	events.runAction('SelectByPath', { current: selectedPath, next: newPath })
	//	console.log('Select by path')
	//	if (!equals(selectedPath, newPath)) {
	//		select(taxonomy.getByPath(newPath))
	//	}
	// }, [selectedPath, taxonomy])

	const next = useCallback(() => events.runAction('NavigateNext'), [])
	const prev = useCallback(() => {
		console.log('Goig prev?!?!')
		events.runAction('NavigatePrev')
	}, [])
	// const next = useCallback(() => select(getSelected().next()), [ getSelected, select ])
	// const prev = useCallback(() => select(getSelected().prev()), [ getSelected, select ])
	// useEventListener()
	const maybeAutoAdvance = useCallback(() => {
		const shouldSkip = getSelected().shouldSkip()
		const selected = getSelected()
		if (!preventAutoAdvance && shouldSkip) {
			const next = selected.next()
			console.log(`Auto-advancing from ${selected?.id} to ${next?.id}`)
			select(next)
		} else {
			console.log(`Not auto-advancing ${selected?.id}`, { preventAutoAdvance, shouldSkip })
		}
	}, [select, preventAutoAdvance, getSelected])

	useLayoutEffect(() => {
		events.runAction('AutoAdvance')
	}, [])

	return useMemo(
		() => ({
			select,
			selected,
			getSelected,

			selectedPath,
			selectByPath,
			next,
			nextUnscored: next,
			prev,
		} as const),
		[ getSelected, selected, select, next, prev, selectByPath, selectedPath ],
	)
}

const defaultTaxonomy = Taxonomy.create({
	Type: 'Parent',
	id: 0,
	_id: 0,
	children: [],
})
export const TaxonomyNavigationContext = createContext<TaxonomyNavigation>({
	path: [0],
	selected: defaultTaxonomy,
})

export const useNavigationContext = () => useContext(TaxonomyNavigationContext)
export const TaxonomyNavigationProvider = hh(TaxonomyNavigationContext.Provider)

	// const selectPath = (selected: TreePath) => {
	//   const item = taxonomy.select(selected)
	//   const path = item
	//     ? item.getPath()
	//     : [0]
	//   setPath(path)
	// }


// let abortNavigation: VoidFn | undefined = () => { }
// const scheduleNext = ({ skipToID, nextPage = true }: INextOptions) => {
//   if (abortNavigation) { abortNavigation() }
//   const { promise, cancel } = wait(20)
//   abortNavigation = cancel
//   const abort = () => abortNavigation = (() => { })
//   promise
//     .then(() => next({ skipToID, nextPage }))
//     // .finally() // finally doesn't exist on native promise?
//     .then(abort)
//     .catch(abort)
// }

// const getSelectedPath = () => {
//   const path = match.params.path || '0,0'
//   const selectedPath = path.split(',').map((idx: string) => parseInt(idx, 10))
//   return selectedPath
// }

// const setUrl = (selectedPath: TreePath) => {
//   const id = get(props, ['assessments', 'selected', '_id'], match.params.id) || 'new'
//   const newUrl = ['/assessment', id, selectedPath.join(',')].join('/')
//   history.push(newUrl)
// }


// import { getPathItems, getSelectedItem, siblingsAreLeafNodes } from './utils'

// interface INextOptions {
//   skipScored?: boolean
//   fromPath?: TreePath
//   skipToID?: number | string
//   nextPage?: boolean
// }
