import { firstNonEmpty, hh, isTruthy } from '~/utils'
import { fileSrc } from '~/utils/assets'
import { Image, Text, View } from '~/utils/Tags/react-native'
import { S } from './styles'
import { Spacer } from '../Helpers/Spacer'
import { getFormValues, ICollectionRendererOptions } from './utils'

import { IPhoto } from '~/interfaces'

import { observer } from 'mobx-react'
export const Caregiver = hh(observer(
	(props: ICollectionRendererOptions) => {
		const response = getFormValues(props.item)
		const image = response['Image(optional)'] as IPhoto
		const name = firstNonEmpty([
			[
				response['First Name'],
				response['Last Name']
			].filter(isTruthy).join(' '),
			'(Empty)',
		])

		return View([
			!!image && Image(S.ItemIcon, fileSrc(image.src)),
			View(S.ListItem, [
				Text(S.ListItemText, [name]),
				Spacer,
				Text(S.ListItemText, [response['Relationship to Client']]),
				Spacer,
				Text(S.ListItemText, [response['Phone Number (Primary)']]),
			]),
		])
	}),
	'Caregiver',
)
