import { find, propEq } from 'ramda'
import { getType } from 'mobx-state-tree'
import { View, Picker, PickerItem, Text } from '~/utils/Tags/react-native'
import { hh } from '~/utils'
import { IItemProps } from '~/interfaces'

import { Styling } from '~/utils'

// import { TextEntry } from '~/ItemTypes/Text'
// import { useNavigationContext } from '../useTaxonomyNavigation'
import { ItemRegistry } from '~/ItemTypes'
import { ItemType } from './'
import { stringifySnapshot } from 'ttss/Taxonomy/utils'
import { pre } from '~/utils/Tags'

const NO_OP = () => ({})
//const alphaCompare = (a: string, b: string) => a.localeCompare(b)
// const questionTypes = Object.values(QUESTION_TYPES)
// 	.sort((a, b) => alphaCompare(a.Type || a.key, b.Type || a.key))
export const DefaultItemEditor = hh(
	({ item, children }: IItemProps) => {

		const itemRenderers = ItemType.registry.list()
		// const { selectedPathItems } = useNavigationContext()

		// find(propEq('Type', item.Type), questionTypes)
		const selectedIdx = itemRenderers.findIndex(([key, itemType]) => key === item.Type)
		const questionType = itemRenderers[selectedIdx]
		// const selectedValue = questionType.name

		return View(S.Editor, [
			children,
			// icon?: 'string'
			Text([ `id: ${item.id}` ]),
			// TextEntry({ label: QuestionPrompt({ text: 'Heading' }), value: item.heading, submit: NO_OP }),
			// TextEntry({ label: QuestionPrompt({ text: 'Description' }), value: item.description, submit: NO_OP }),
			// TextEntry({ label: QuestionPrompt({ text: 'Alternative Prompt' }), value: item.prompt, submit: NO_OP }),
			// QuestionPrompt({ text: 'Question Type' }),
			// // Picker({ selectedValue, onValueChange: NO_OP }, [
			// // 	questionTypesList.map(question => PickerItem({ label: question.name })),
			// // 	// + ` (${question.key})` })),
			// // ]),
			ItemField([
				Text([ `QuestionType: ${getType(item).name}(${item.Type})` ]),
			]),
			ItemField([
				Text(['Display inline: ', item.inline, 'Checkbox' ]),
			]),
			// Text([ 'Branch when: ', item.branchWhen ]),
			// // Text([ item.matrixKeys ]),
			// View(questionType.options.map((option) => Text([option]))),
			// options?: string[]
			// children?: ITaxonomyInstance[]
			// button?: string
			pre([stringifySnapshot(item)])
		])
	},
	'ItemEditor',
)
const S = Styling({
	Editor: {
		border: '2px solid yellow',
		borderRadius: 4,
		backgroundColor: '#739cc0',
		padding: '1em'
	},
	ItemField: {
		margin: '.2em',

	},
})

const ItemField = hh(
	({ children }) => View(S.ItemField, [ children ]),
	'ItemField',
)
