import { svg } from '~/utils/assets'
import { Image, Text } from '~/utils/Tags/react-native'
import { S } from './styles'

const Checkmark = Image(S.Checkmark, svg('checkmark.svg'))
// export enum Progress { UnScored, InProgress, Scored }
export enum Progress { UnStarted, InProgress, Completed }
export const progressIndicators = {
	[Progress.InProgress]: Text(S.Score, ['IP']),
	[Progress.UnStarted]: [],
	[Progress.Completed]: Checkmark,
}

export function ProgressIndicator(progress: Progress) {
	return progressIndicators[progress]
}
