import { TaxonomyInstance } from '~/interfaces'
import { OutlineItem } from './OutlineItem'

export const OutlineGroup = (items: TaxonomyInstance[]) =>
  items.map(
    (item, index) => OutlineItem({
      item,
      key: '' + item.id + index,
    })
	)


