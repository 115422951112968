import { Styling } from '~/utils'

export const S = Styling({
	AutoFilled: {
		fontWeight: 'bold',
	},
	MultipleChoiceQuestion: {
		flexDirection: 'column',
		marginTop: 10,
		marginBottom: 10,
	},
	Option: {
		flex: 1,
		marginTop: 10,
		marginRight: 10,
		flexDirection: 'row',
		alignItems: 'center',
	},
	// Option: {
	// 	flex: 1,
	// 	marginTop: 10,
	// 	marginRight: 10,
	// 	flexDirection: 'row',
	// 	alignItems: 'center',
	// }
	ButtonOption: {
		flex: 1,
		padding: 10,
		borderStyle: 'solid',
		borderColor: 'black',
		borderWidth: 3,
		borderRadius: 10,
		width: 100,
		justifyContent: 'center',
	},
	ButtonText: {
		fontWeight: 'bold',
		textAlign: 'center',
	},
	RadioOuter: {
		width: 20, height: 20,
		borderRadius: '100%',
		borderColor: 'black',
		borderWidth: 3,
		marginRight: '1em',
	},
	RadioSelected: {
		width: '100%', height: '100%',
		borderWidth: 3,
		borderRadius: '100%',
		backgroundColor: 'black',
		borderColor: 'white',
	},
	CheckboxText: {
		marginLeft: 10,
	},
	Checkbox: {
		marginRight: '1em',
	},
	InlineOptionsDisplay: {
		flexDirection: 'row',
		alignSelf: 'flex-start',
	},
	VerticalOptionsDisplay: {},
	OtherOptionContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'baseline',
		marginTop: 10,
		marginRight: 10,
		// flexGrow: 1,
	},
	OtherOptionInput: {
		borderWidth: 1,
		borderColor: 'lightgrey',
		// width: '100%',
		flexGrow: 1,
	},
	AddOption: { },
	AddOptionText: {
		marginTop: '1em',
		marginLeft: '2em',
		color: 'blue',
	},
	DeleteOption: {
		fontWeight: 'bold',
		color: 'red',
	},
})
