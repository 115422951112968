import { Attachment } from '~/ItemTypes/Attachment'
import { Collection } from '~/ItemTypes/Collection'
import { DateRenderItem } from '~/ItemTypes/Date'
import { Group } from '~/ItemTypes/Group'
import { MultipleChoiceItem } from '~/ItemTypes/MultipleChoice'
import { HorizontalSeparatorItem } from '~/ItemTypes/Helpers/HorizontalSeparator'
import { ProfilePickerItem } from '~/ItemTypes/ProfilePicker'
import { TextItem } from '~/ItemTypes/Text'
import { ParentItem } from '~/ItemTypes/Parent'
import { ScoreableItem } from '~/ItemTypes/MultipleChoice'
import { Proxy } from '~/ItemTypes/Proxy'
import { Fallback } from './ItemTypes/Fallback'
import { ItemRegistry } from './ItemTypes'

const itemRegistry = new ItemRegistry(Fallback)
// TODO: standardize `Item` suffix. Currently not done because of
//       inconvenience do to name conflicts in ttss package
itemRegistry.register(TextItem)
itemRegistry.register(Group)
itemRegistry.register(DateRenderItem)
itemRegistry.register(Attachment)
itemRegistry.register(Collection)
itemRegistry.register(ParentItem)
itemRegistry.register(ProfilePickerItem)
itemRegistry.register(Proxy)
itemRegistry.register(ScoreableItem)
itemRegistry.register(MultipleChoiceItem)
itemRegistry.register(HorizontalSeparatorItem)

// TODO: This is implemented as a stateful global, but other
//       implementation should be explored.
import { ItemType } from './Item'
ItemType.registry = itemRegistry

// import { PhotosItem } from '~/ItemTypes/Photos'
// registerItemRenderer(QCode.Fallback, Fallback),
// registerItemRenderer(QCode.Panorama, Fallback),
// registerItemRenderer(QCode.DnD, Fallback),
// QType.Photo: PhotosItem,
