import { memo, useCallback, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
// import { TopNavigation } from '~/components/TopNavigation'
import { AssessmentInstance, Assessment as AssessmentModel } from 'ttss'
import { AssessmentProvider } from './AssessmentContext'
import { useSetupTaxonomyNavigationContext, TaxonomyNavigationProvider } from './useTaxonomyNavigation'
import { Text, Image, ScrollView, TouchableOpacity, View } from '~/utils/Tags'
import { TaxonomyInstance, VoidFn } from '~/interfaces'
import { h, hh, useToggler, applyPartialPatch, loadAssessment } from '~/utils'
import { Footer } from './Footer'
import { Outline } from './Outline'
import { S } from './styles'
import { ContentArea } from './ContentArea'
import { DebugView } from './DebugView'
import { SnapshotIn } from 'mobx-state-tree'
import { useUpdateEffect } from '@react-hookz/web'
import { useEventContext } from '~/EventSystem'

export interface AssessmentProps {
	assessment: AssessmentInstance
	newAssessment(): void
	save(): void
	load(assessment: SnapshotIn<typeof AssessmentModel>): void
}

/* Taxonomy.create({
 *	_id: '123',
 *	id: '123',
 *	Type: 'MCQ',
 *	config: { isSingleSelect: true },
 *	children: [{
 *		_id: '1235',
 *		id: '1235',
 *		Type: 'MCQ',
 *		config: { isSingleSelect: true },
 *	}]
 * }) */

// 1. Register an Item renderer
//    1a. Item renderer registers rules?

function ObservedAssessment({ assessment, newAssessment, save, load }: AssessmentProps) {
	//console.log('Assessment: ', assessment._id)
	const events = useEventContext()
	const taxonomy = assessment.taxonomy as TaxonomyInstance
	const taxonomyNavigation = useSetupTaxonomyNavigationContext({ taxonomy, path: [0], save })
	const { selectByPath } = taxonomyNavigation
	// Any time the taxonomy or assessment is changed, redirect to the first page
	// TODO: afterTaxonomyLoad || afterAssessmentLoad ?
	useUpdateEffect(() => { selectByPath([0]) }, [ assessment, taxonomy ])

	const AssessmentContextValue = useMemo(() => ({
		value: {
			assessment,
			taxonomy,
			save,
			newAssessment,
			load,
		}
	}), [assessment, taxonomy, save, newAssessment, load])
	const TaxonomyContextValue = useMemo(() => ({ value: taxonomyNavigation }), [ taxonomyNavigation ])

	return AssessmentProvider(AssessmentContextValue, [
		TaxonomyNavigationProvider(TaxonomyContextValue, [
			Layout()
		])
	])
}

const Layout = hh(memo(() => {
	return View(S.Assessment, [
		View(S.Main, [
			// TopNavigation({ title: items[0].heading, syncStatus }),
			// View(),
			View(S.MainInner, [Outline()]),
			ContentArea(),
			DebugView(),
		]),
		Footer(),
	])
}))

ObservedAssessment.whyDidYouRender = false
export const Assessment = hh(observer(ObservedAssessment), 'Assessment')
Assessment.whyDidYouRender = false

// const [showCommentPopUp, toggleCommentPopup] = useToggler(false)
// const [showCameraPopUp, toggleCameraPopup] = useToggler(false)
// const closePopups = () => {
//   toggleCameraPopup(false)
//   toggleCommentPopup(false)
// }

// constructor(props: any, context: any) {
//   // Select an existing assessment or create a new one
//   if (params.id) {
//     // console.log(`Assessment ${params.id} requested`)
//     if (!props.assessments.selected || params.id !== props.assessments.selected._id) {
//       // console.log(`Assessment ${params.id} exists, selecting`)
//       assessments.select(params.id)
//     }
//   } else {
//     logger.info('Creating New Assessment!')
//     const created = assessments.createDoc({ _id: 'new', taxonomy: 0 })
//     // const created = assessments.createDoc({ _id: 'new', taxonomy: 'AccessTools' })
//     assessments.select(created)
//   }
// }

// TODO: move auto-advancing code to separate function
// @debounceTime(1000)
// const submit = async <T extends TaxonomyInstance>(item: T, _options: ISubmitOptions) => {
//   const { assessments } = this.props
//   const assessment = assessments.selected
//   if (!assessment) return
//   const { autoAdvance, save } = { autoAdvance: true, save: true, ..._options }
//   // Saving
//   if (save) {
//     logger.info('Saving initiated by id:', item.id)
//     const saved = await assessment.save()
//     if (assessment._id === 'new') {
//       // logger.error('Why new?', assessment, saved)
//       // TODO: is this needed? done automatically now? Investigate.
//       assessments.put(saved)
//       assessments.select(saved._id)
//       // update the url to include the assessment id instead of 'new'
//       this.setUrl(this.getSelectedPath())
//     }
//   }
//   // Auto-advancing
//   if (item && autoAdvance && item.shouldAutoAdvance) {
//     // const fromPath = findPathToItem(next => next.id !== item.id, this.taxonomy)
//     // const fromPath =
//     // const skipToID = item.branchWhen && item.branchWhen[newValue[0]]
//     // const skipToID = undefined
//     this.scheduleNext({ nextPage: false })
//   }
// }
