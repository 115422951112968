import { Styling } from '~/utils'

const S = Styling({
	Icon: {
		width: 15,
		height: 15,
	},
	IconWrapper: { aspectRatio: 1, alignItems: 'center', justifyContent: 'center', height: '100%' },
	Notes: {
		justifyContent: 'flex-end',
		zIndex: 100,
		position: 'relative',
		borderTopRightRadius: '10px',
		borderTopleftRadius: '10px',
	},
	NotesBackdrop: {
		position: 'fixed',
		left: 0, top: 0,
		// opacity: 0.6,
		backgroundColor: 'black',
		width: '100vw',
		height: '100vh',
		zIndex: 1,
	},
	Handle: {
		zIndex: 1,
		userSelect: 'none',
		// flex: 1,
		backgroundColor: 'white',
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 3,

		// Add a little overlap to cover up a weird seam while animating
		position: 'relative',
		paddingBottom: 6,
		//top: '3px',
	},
	HandleIcon: {
		height: 45,
		width: 45,
		zIndex: 1,
	},
	HandleLabel:  {
		color: 'black',
		flex: 1,
		alignItems: 'center',
	},
	HandleLabelText: {
		fontSize: 14,
	},
	NotesView:  {
		backgroundColor: 'white',
		flex: 1, flexDirection: 'column',
		position: 'absolute', top: '99%', left: 0,
		width: '100vw', maxWidth: 'min(80ex, 100vw)', minWidth: '10em',
		height: '200vh',
		padding: 10,
		borderTopRightRadius: '3px',
		boxShadow: '7px 2px 15px -1px rgba(0,0,0,0.66)',
	},
	NotesInput: { backgroundColor: '#E8E8E8', padding: 2, height: 80, color: 'black' },
	NotesHeader: { fontSize: 14 },
	MainControls: {
		flex: 2,
		marginLeft: 10,
		flexDirection: 'row',
	},
	NavigationButtons: {
		flex: 2,
		marginLeft: 10,
		flexDirection: 'row',
	},
	NavButton: { borderRadius: 40, overflow: 'hidden', margin: 10 },
	ButtonContainer: { flex: 1, flexDirection: 'column' },
	Footer: {
		color: 'white',
		flexDirection: 'column',
		// overflow: 'hidden',
	},
	FooterButtons: {
		color: 'white',
		height: 50, flexDirection: 'row', marginLeft: 10, marginRight: 10
	},
})

export { S }
