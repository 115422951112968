export const PRIMARY_COLOR = '#D0E8DA'
export const ON_PRIMARY_COLOR = 'black'

export const TERTIARY_COLOR = '#CF6F59'

export const TT_COLOR = ''

export const SURFACE_COLOR = 'white'
export const ACCENT_COLOR = 'white'

export const SECONDARY_COLOR = '#55444A'

// surface, error, background

// export const SECONDARY_COLOR = '#56474C'


