import { hh } from '~/utils'
import { S } from './styles'
import { Text, Image, ScrollView, TouchableOpacity, View } from '~/utils/Tags/react-native'
import { getItemRenderer } from '~/ItemTypes'
import { RenderItem } from '~/ItemTypes/Page'
import { getOrdinal, getPathItems, getSelectedItem, siblingsAreLeafNodes } from './utils'
import { useNavigationContext } from './useTaxonomyNavigation'
import { ItemType } from '~/Item'

export const ContentArea = hh(
	() => {
		const { selected } = useNavigationContext()
		const shouldShowSiblings = selected.siblingsAreLeaves // siblingsAreLeafNodes(selected.siblings)
		// const items = getPathItems(selected, taxonomy)
		// const breadcrumbs = selected.getAncestors()


		// Ensures that multiple leaf nodes are rendered on the same page
		return View(S.Content, [
			ScrollView(S.PageScroll, [
				// Text(breadcrumbs.map((item, idx) => `${idx}-${item.selfIndex}-` + getOrdinal(idx+1, item.selfIndex) + '. ' + item.heading)),
				View(S.PageWrapper, ItemType.render(selected)),
				// View(S.PageWrapper, ),
			]),
		])
	},
	'ContentArea',
)
