import { useMemo } from 'react'
import { equals } from 'ramda'

import { QCode } from 'ttss/Taxonomy/utils'
import { Styling } from '~/utils'
import { View } from '~/utils/Tags'
import { useNavigationContext } from '~/Assessments/useTaxonomyNavigation'
import { ACCENT_COLOR, SECONDARY_COLOR, TERTIARY_COLOR } from '~/Assessments/globals'
import { generateItemKey } from './utils'
import { ItemContainerProps } from './interfaces'

/**
 * The overal container for an item.
 * Defines: Border, background, Breadcrumb
 */
export const DefaultItemContainer =
	(props: ItemContainerProps) => {
		const { item, children } = props
		const { id, config, items, selfIndex } = item
		const { selected } = useNavigationContext()
		const isGroup = item.Type === QCode.Group
		const containsSelection = item === selected || item.descendants.find(equals(selected)) || isGroup

		const style = useMemo(() => {
			const styles = props.style || {}
			return containsSelection ? {
				// ...flexDirection,
				...SelectedStyle,
				...styles,
			}
				: props.style
		}, [props.style, containsSelection])

		return View(S.ItemContainer, {
			title: item.outlineText, // stringify({ item })
			'data-id': id,
			key: generateItemKey(item),
			style,
		}, [
			children
		])
	}

const SelectedBorderWidth = 4
const S = Styling({
	ItemContainer: {
		// display: 'block',
		// alignItems: 'flex-start',
		display: 'flex',
		// flex: 1,
		flexShrink: 1,
		flexGrow: 1,
		padding: '.2em',
		paddingLeft: '.8em',
		paddingRight: '1em',
		// minWidth: '65ex',
		maxWidth: '65ex',
		backgroundColor: ACCENT_COLOR,
		borderRadius: '.4em',
		borderStyle: 'inset',
		marginBottom: '.2em',
		overflow: 'hidden',
	},
	Selected: {
		borderColor: 'lightblue',
		borderLeftWidth: `${SelectedBorderWidth}px`,
		paddingLeft: `${SelectedBorderWidth}px`,
		marginLeft: `-${SelectedBorderWidth * 2}px`,
	}
})
const SelectedStyle = {
	border: `2px solid ${TERTIARY_COLOR}`
	// borderColor: 'lightblue',
	// borderLeftWidth: `${SelectedBorderWidth}px`,
	// paddingLeft: `${SelectedBorderWidth}px`,
	// marginLeft: `-${SelectedBorderWidth * 2}px`,
	// background: `rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(173, 216, 230) 0%, rgb(255, 255, 255) 100%) repeat scroll 0% 0%`,
}
