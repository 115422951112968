import { Styling } from '~/utils'
import { SECONDARY_COLOR, TERTIARY_COLOR, PRIMARY_COLOR, SURFACE_COLOR } from './globals'
const S = Styling({
	background: {
		// display: 'block',
		height: '100%',
		overflow: 'hidden',
		// flex: 1,
		// flexDirection: 'column',
		// justifyContent: 'stretch',
		// alignItems: 'stretch',
		// width: '100%',

		// height: '100%',
		backgroundColor: SECONDARY_COLOR,
	},
	backdrop: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		backgroundColor: 'black',
		opacity: '0.7',
	},
	Main: {
		flex: 1, flexDirection: 'row', margin: 10, borderRadius: 10,
		overflow: 'hidden',
		
		// display: 'grid',
		// gridTemplateColumns:  '1fr 4fr',
		// gridTemplateRows: '5fr 1fr',
		
		backgroundColor: SECONDARY_COLOR,
	},
	MainInner: {
		flex: 1,
		flexDirection: 'row',
		marginRight: 10,
		borderRadius: 0,

		overflow: 'hidden',
		flexGrow: 2,
		maxWidth: '25em',
	},
	Content: {
		flex: 1,
		flexGrow: 4,
		backgroundColor: PRIMARY_COLOR,
		borderTopLeftRadius: 10, flexDirection: 'column',
	},
	ContextSpecificNotesButtons: {
		float:'right',
		flexDirection: 'row',
	},
	ContextualButtons: { width: 15, height: 15, marginRight: 5 },
	PageScroll: {
		flex: 1, padding: 15,
		flexDirection: 'column',
		alignSelf: 'stretch',
		alignItems: 'stretch'
	},
	PageWrapper: {
		alignItems: 'stretch'
	},
	Assessment: {
		alignItems: 'stretch',
		backgroundColor: SECONDARY_COLOR,
		flex: 1,
		width: '100vw',
		height: '100vh',
		maxHeight: '100vh',
		maxWidth: '100vw',
		overflow: 'hidden',
		justifyContent: 'flex-start',
	},
})

export { S }
