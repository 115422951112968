import { svg } from '~/utils/assets'
import {
	Image,
	TextInput,
	View,
} from '~/utils/Tags/react-native'

const style = {
	flex: 1,
	maxWidth: 250,
	backgroundColor: '#E8E8E8',
	padding: 2,
	borderRadius: 50,
}

export const UserPicker = () => View({ style: { flexDirection: 'row' } }, [
	Image({ style: { width: 32, height: 32 }, ...svg('search_icon.png') }),
	TextInput({ style, defaultValue: 'Search HESTIA users...' }),
])

