import { Text, View } from '~/utils/Tags/react-native'
import { IItemProps } from '~/interfaces'
import { MultipleChoiceItem } from 'ttss/Taxonomy/MultipleChoiceItem'
import { AnswerOptionTypes, getAnswerOption } from 'ttss/Taxonomy/Items/MultipleChoiceItem/AnswerOption'
import { getQuestionText, ItemLabel, QuestionPrompt } from '~/Item/Prompt'
import { S } from './styles'
import { HComponent } from '~/interfaces'
import { useContext, useCallback } from 'react'
import { AssessmentContext } from '~/Assessments/AssessmentContext'
import { CustomOptionsControls, OptionRendererProps, PredictionProps } from './utils'
import { hh, logGroup, useHandler } from '~/utils'
import { observer } from 'mobx-react'
import { useNavigationContext } from '~/Assessments/useTaxonomyNavigation'
type IMultipleChoiceQuestionProps = IItemProps<typeof MultipleChoiceItem>

export interface MCQOptions {
	select(item: any ): (option: AnswerOptionTypes, idx: string | number) => void
	inline: boolean
}

/**
 * Augment an OptionControl with the basic MultipleChoiceQuestion functionality.
 */
export const MultipleChoiceQuestion = (optionComponent: HComponent<OptionRendererProps>, options?: MCQOptions) =>
	hh(observer((props: IMultipleChoiceQuestionProps & CustomOptionsControls & PredictionProps) => {
		const {
			item,
			getOtherOptions,
			renderOtherOptionControl,
			renderAddOptionControl,
			predictedOption,
			predictionError,
		} = props
		const { save } = useContext(AssessmentContext)
		const { select: selectItem } = useNavigationContext()
		const defaultSelect =
			useCallback(
				(option: AnswerOptionTypes, idx: string | number, item, selectItem) => () => {
					const itemSelect = options?.select?.(item)
					if (itemSelect) return itemSelect(option, idx, item, selectItem)
					const { name } = getAnswerOption(option)
					item.selectOption(name)
					save(item)
				},
				[item, save],
			)
		const select = options?.select?.(item) ?? defaultSelect
		const isSelected = options?.isSelected ?? item.isSelected

		const renderOptionControl = useCallback(
			(option: AnswerOptionTypes, idx: string | number, showLabel: boolean = true) => {
				const { name, description } = getAnswerOption(option)
				const label = name + (
					description
						? ' - ' + description
						: ''
				);

				return optionComponent({
					label,
					// name
					showLabel,
					selected: isSelected(option, idx, item),
					select: logGroup('Answering', { option, idx, item, selectItem })(select(option, idx, item, selectItem)),
					idx,
					key: label+idx,
					predicted: predictedOption,
				});
			}, [ select, predictedOption, item ])

		const config = item.config
		const optionsControls = item.options.map(renderOptionControl)
		const otherOptionsControls = getOtherOptions?.()
																										.map((option, idx) => {
																											const key = idx + (item.options?.length ?? 0)
																											return renderOtherOptionControl(renderOptionControl(option, idx, false), option, idx)
																										})

		const flowStyle = options?.inline
			? S.InlineOptionsDisplay
			: S.VerticalOptionsDisplay

		return View(S.MultipleChoiceQuestion, [
			View(flowStyle, [
				optionsControls,
				otherOptionsControls,
			]),
			config.allowOther && renderAddOptionControl?.(),
			predictionError && Text([predictionError]),
		])
	}), 'MultipleChoiceQuestion')
