import { JSONTree as _JSONTree } from 'react-json-tree'
import { useNavigationContext } from './useTaxonomyNavigation'
import { Styling, h, hh, div, pre } from '~/utils/Tags'
import { useHotkeys } from 'react-hotkeys-hook'
import { useToggle } from '@react-hookz/web'
import { intersection, omit, pick } from 'ramda'
import isPlainObject from 'is-plain-obj'
import { childrenAreLeafNodes } from './utils'
const excludedKeys = ['next', 'prev', 'iterateLevel', 'iterateNext', 'nextSibling', 'prevSibling']

const JSONTree = hh(_JSONTree, 'JSONTree')

const includedKeys = [
	'id',
	'Type',
	'heading',
	'Name',
	'icon',
	'prompt',
	'description',
	'inline',
	'matrixKeys',
	'predicate',
	'branchWhen',
	'isTaxonomy',
	'_response',
	'response',
	'responseValue',

	'itemParentIsSelf',
	'hasChild',
	'shouldOmit',
	'filteredItems',
	'isLeaf',
	'childrenAreLeaves',
	'selfIndex',
	'isInCollection',
	'isLastSibling',
	'siblingsAreLeaves',

	'hasSiblings',
	'isLastLeafSibling',
	'depth',
	'shouldSkipChildren',
	'isRelevant',
	'lastDescendant',
	'isInPage',
	'isLastInPage',
	'isSelectable',
	'shouldSkip',
	'isLastOnPage',
	'childProgress',
	'selfProgress',
	'getScore',
	'getChildScores',
	'progress',
	'shouldAutoAdvance',
	'showChildrenInOutline',
	'typeName',
	'outlineText',
	'isPage',
]
export const DebugView = hh(
	() => {
		const [showDebug, toggleShowDebug] = useToggle(false)
		const { selected, selectedPath } = useNavigationContext()

		useHotkeys('ctrl+/', () => toggleShowDebug())

		return showDebug && div(S.Debug, [
			selectedPath.join('->'),
			JSONTree({
				data: selected,
				hideRoot: true,
				postprocessValue(value) {
					if (value?.$treenode) return pick(includedKeys, value)
					if (typeof value === 'function') return 'fn'
					// if (isPlainObject(value)) return omit(excluded)

					return value
				},
			}),
			// pre([ JSON.stringify(selected.toJSON(), undefined, 2) ])
		])
	},
	'DebugView'
)

const S = Styling({
	Debug: {
		backgroundColor: 'lightgray', padding: '1em',
		maxWidth: '100%', overflow: 'auto',
		maxHeight: '100%',
		position: 'absolute', bottom: 0, left: '50%', marginLeft: 'auto', marginRight: 'auto'
	}
})
