import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, {
		// trackAllPureComponents: true,
		trackExtraHooks: [
			// [ReactRedux, 'useSelector']
			//
		]
	});
}

import { setStylesTarget } from 'typestyle'
import { AppRegistry } from 'react-native'

setStylesTarget(document.getElementById('styles-target'))
const root = document.getElementById('root')
const StrictApp = () => (
	<React.StrictMode>
		<App />
	</React.StrictMode>
)

AppRegistry.registerComponent('App', () => StrictApp)
AppRegistry.runApplication('App', {
	initialProps: {},
	rootTag: root,
})


/* ReactDOM.render(
 * 	<React.StrictMode>
 * 		<App />
 * 	</React.StrictMode>,
 * 	root,
 * );
 *  */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
