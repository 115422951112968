import { hh } from '~/utils/Tags/hh'
import { TouchableOpacity, View, Text } from '~/utils/Tags/react-native'

export { IconButton } from './Button'
export { PhotoAttachmentView } from './AttachmentView'
export { UserPicker } from './UserPicker'

export const Btn = hh(
	({
		color = 'white',
		backgroundColor = 'rgb(33, 150, 243)',
		title,
		style = {},
		children,
		...rest
	}) => {
		return TouchableOpacity({
			style: {
				backgroundColor,
				padding: 10,
				borderRadius: 2,
				...style
			},
			...rest
		}, [
			title && Text({ style: { color, fontWeight: 500, textTransform: 'uppercase' } }, [title]),
			children
		])
	},
	'Btn',
)

export const Section = hh(
	({
		style = {},
		children,
		...rest
	}) =>
		View({ style: { border: '1px solid gray', borderRadius: '5px', padding: '1em', ...style }, ...rest }, children),
	'Section',
)
export const Heading = hh(
	({ children }) => {
		return Text({ style: { fontSize: 14, fontWeight: 'bold' } }, [children])
	},
	'Heading',
)
