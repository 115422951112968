import { CheckBox, Text, TouchableOpacity } from '~/utils/Tags/react-native'
import { S } from './styles'
import { OptionRenderer, OptionRendererProps } from './utils'

export const Checkbox: OptionRenderer = (props: OptionRendererProps) => {
	const {
		label,
		showLabel,
		selected,
		select,
		idx,
		predicted = -1,
	} = props

	return TouchableOpacity(S.Option, { key: idx+label, onPress: select }, [
		CheckBox(S.Checkbox, { value: !!selected }),
		showLabel && (typeof label === 'string' ? Text(S.CheckboxText, [ label ]) : label),
		predicted === idx && Text(S.AutoFilled, [' ← Predicted']),
	])
}
