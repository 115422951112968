import { Text, TouchableOpacity, View } from '~/utils/Tags/react-native'
import { hh } from '~/utils'
import { S } from './styles'
import { OptionRenderer, OptionRendererProps } from './utils'
import { observer } from 'mobx-react'

export const Button: OptionRenderer = hh(
	observer((props: OptionRendererProps) => {
		const { label, selected, select, idx, predicted } = props
		const [color, backgroundColor] = selected
			? ['white', 'black']
			: ['black', 'white']

		const optionView = View(S.ButtonOption, { style: { backgroundColor } }, [
			Text(S.ButtonText, { style: { color } }, [label]),
		])

		return TouchableOpacity(S.Option, { key: label+idx, onPress: select, style: { alignItems: 'stretch' } }, [
			idx === predicted
				? View([optionView, Text(S.AutoFilled, ['↑ Predicted'])])
				: optionView
		])
	}),
	'ScoreableOption',
)

export const Radio: OptionRenderer = hh(observer(
	({ label, selected, select, idx, predicted }: OptionRendererProps) =>
		TouchableOpacity(S.Option, { key: label+idx, onPress: select }, [
			RadioButton(selected),
			Text([label]),
			predicted === idx && Text(S.AutoFilled, [' ← Predicted']),
		]),
	'RadioOption',
))
