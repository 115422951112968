import { QCode } from 'ttss/Taxonomy/utils'

import { Styling } from '~/utils'
import { IItemProps } from "~/interfaces"
import { View, Text } from '~/utils/Tags/react-native'
import { ItemRegistry } from '~/ItemTypes'
import { ItemType } from '~/Item'

const S = Styling({
	Parent: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
})


export const ParentItem = ItemType.create({
	name: QCode.Parent,
	field: (props: IItemProps<any>) => {
		return props.item.isInPage()
			? View(S.Parent, [
				ItemRegistry.lookup(QCode.Group)(props)
			])
			: Text([props.item.heading]) // probably a heading being rendered in a page
	},
})
